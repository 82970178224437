import React, {
	FC,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import {
	Box,
	Button,
	Container,
	Flex,
	FormLabel,
	Heading,
	Text,
	useMediaQuery,
} from '@chakra-ui/react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { FormProvider, get, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import {
	CurrencyInputHook,
	MultiSelectCreateableInputHook,
	MultiSelectInputHook,
	MultiSelectValuesHook,
	PromoCodesInputHook,
	RadioGroupInputHook,
	StringInputEditableHook,
	StringInputHook,
	TextareaInputHook,
} from '../common/form';
import InformationBanner from '../common/InformationBanner';
import { ProductFormModal } from '../commerce/products';
import ConfigFormModal from '../config/ConfigFormModal';
import MarketingChannelsForm from './parts/MarketingChannelsForm';
import CampaignGoalsList from './parts/CampaignGoalsList';
import { AppInputConfigContext, CampaignContext } from 'src/contexts';
import useCampaignConfig from 'src/hooks/useCampaignConfig';
import useToggleWithPayload from 'src/hooks/useToggleWithPayload';
import useFetch from 'src/hooks/useFetch';
import useAccountConfigOptions from 'src/hooks/config/useAccountConfigOptions';
import useAutoSaveCampaign from 'src/hooks/useAutoSaveCampaign';
import useCatalogKinds from 'src/hooks/useCatalogKinds';
import {
	createAccountConfigOptions,
	getAccountConfigOptions,
} from 'src/services/account';
import {
	CampaignFormSchema,
	ECampaignPromotedType,
	ICampaignForm,
	ICatalog,
	ICatalogForm,
	IChannel,
} from 'src/lib/schemas';
import { IAccountConfigOption, IOption } from 'src/lib/schemas/misc';
import { delay, transformCampaignToForm } from 'src/lib/utils';
import { toastError } from 'src/services/toast';
import { getPromotedObjectOptions } from './utils/options';
import { createOrUpdateCampaign } from 'src/services/campaign';

const CHANNELS = [
	'facebook',
	'instagram',
	'twitter',
	'google',
	'sms',
	'pns',
	'email',
];

interface IQueryState {
	option: IOption;
	type: ECampaignPromotedType;
	selectedChannel?: string;
}

interface CampaignFormProps {
	group?: string;
	availableChannels: IChannel[];
	onCampaignTitleChange: (title: string) => void;
	onCampaignSubmit: (totalBudget: number) => void;
	onMetaOptionsChange: (options: IOption[]) => void;
	selectedPlacements: string[];
	setSelectedPlacements: React.Dispatch<React.SetStateAction<string[]>>;
}

const CampaignForm: FC<CampaignFormProps> = ({
	group,
	availableChannels,
	onCampaignTitleChange,
	onCampaignSubmit,
	onMetaOptionsChange,
	selectedPlacements,
	setSelectedPlacements,
}) => {
	const formProductToggle = useToggleWithPayload<Partial<ICatalog>>();
	const formToneToggle = useToggleWithPayload<IAccountConfigOption>();
	const formAudienceToggle = useToggleWithPayload<IAccountConfigOption>();
	const [isCampaignLoaded, setIsCampaignLoaded] = useState(false);
	const [isLargerThan1163] = useMediaQuery('(min-width: 1163px)');
	const { inputConfig, loadingInputs, loadedInputs } = useContext(
		AppInputConfigContext,
	);

	const { fetchConfig: fetchTones, createConfig: createTone } =
		useAccountConfigOptions('Tone');
	const { fetchConfig: fetchAudiences, createConfig: createAudience } =
		useAccountConfigOptions('Audience');

	const {
		catalogKinds,
		isLoading: isCatalogKindsLoading,
		error: catalogKindsError,
	} = useCatalogKinds();

	const topElementRef = useRef<HTMLDivElement>(null);
	const [queryState, setQueryState] = useState<IQueryState | null>(null);
	const [isChannelsSelected, setIsChannelsSelected] = useState(false);
	const [selectedChannels, setSelectedChannels] = useState<string[]>();
	const [isPromotedObjectLoaded, setIsPromotedObjectLoaded] = useState(false);
	const { campaign, id: campaignId, setCampaign } = useContext(CampaignContext);
	const location = useLocation();
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	const locationState = location.state as IQueryState;

	const formMethods = useForm<ICampaignForm>({
		resolver: zodResolver(CampaignFormSchema),
	});

	const {
		formState: { isSubmitting },
	} = formMethods;

	const channelsErrors = get(formMethods.formState.errors, 'channels');
	const goalsErrors = get(formMethods.formState.errors, 'goal');

	const title = formMethods.watch('title');
	const watchedDescription = formMethods.watch('description');
	const goals = formMethods.watch('goal') ?? [];
	const goalsList = formMethods.watch('goalsList') ?? [];
	const promotedObjectType = formMethods.watch('promotedObject.type');
	const destination = formMethods.watch('destination');
	const channels = formMethods.watch('channels') ?? {};
	const watchedPromotedObjectValue = formMethods.watch('promotedObject.value');
	const watchedDestination = formMethods.watch('destination');
	const watchedGoals = formMethods.watch('goal') ?? [];
	const watchedAudience = formMethods.watch('audience');
	const watchedTones = formMethods.watch('tone');
	const watchedPromotion = formMethods.watch('promotion');
	const watchedBudgetTotal = formMethods.watch('budget.total');
	// const watchedChannels = Object.keys(channels).filter(
	// 	(item) => channels[item],
	// );

	// const channelSelected = Object.values(channels).find((item) => item === true);

	useEffect(() => {
		onCampaignTitleChange(title);
	}, [title]);

	useEffect(() => {
		if (goals.length && goalsErrors) {
			formMethods.clearErrors('goal');
			formMethods.clearErrors('goalsList');
		}
	}, [goals]);

	useEffect(() => {
		if (loadingInputs['Tone'] || loadedInputs['Tone']) return;
		fetchTones();
	}, []);

	useEffect(() => {
		if (loadingInputs['Audience'] || loadedInputs['Audience']) return;
		fetchAudiences();
	}, []);

	useEffect(() => {
		if (locationState && locationState.option) {
			setQueryState(locationState);
			formMethods.setValue('promotedObject.value', [locationState.option]);
			formMethods.setValue('destination', locationState.option.url ?? '');
			formMethods.setValue('promotedObject.type', locationState.type);
		}
	}, [locationState, formMethods]);

	useEffect(() => {
		if (!promotedObjectType) return;
		if (queryState) {
			setQueryState(null);
		} else {
			formMethods.setValue('promotedObject.value', []);
			formMethods.setValue('destination', '');
		}
	}, [promotedObjectType]);

	useEffect(() => {
		if (title) {
			formMethods.clearErrors('title');
		}
		if (watchedDescription) {
			formMethods.clearErrors('description');
		}
		if (watchedDescription) {
			formMethods.clearErrors('description');
		}
		if (watchedAudience?.length) {
			formMethods.clearErrors('audience');
		}
		if (watchedTones?.length) {
			formMethods.clearErrors('tone');
		}
		if (watchedPromotion?.length) {
			formMethods.clearErrors('promotedObject.value');
		}
		if (destination) {
			formMethods.clearErrors('destination');
		}
	}, [
		title,
		watchedDescription,
		watchedAudience,
		watchedTones,
		destination,
		watchedPromotedObjectValue,
	]);

	// useEffect(() => {
	// 	if (channelSelected) {
	// 		formMethods.clearErrors('channels');
	// 	}
	// }, [channelSelected]);

	useEffect(() => {
		if (locationState?.selectedChannel) {
			setIsChannelsSelected(true);
			switch (locationState.selectedChannel) {
				case 'all':
					setSelectedChannels(CHANNELS);
					break;
				case 'direct':
					setSelectedChannels(['sms', 'pns', 'email']);
					break;
				default:
					setSelectedChannels([locationState.selectedChannel]);
					break;
			}
		}
	}, [locationState]);

	useEffect(() => {
		if (!selectedChannels || !availableChannels) return;
		const channelObj = availableChannels.reduce(
			(acc, channel) => ({
				...acc,
				[channel.id]: selectedChannels?.includes(channel.id),
			}),
			{},
		);
		// formMethods.setValue('channels', channelObj);

		// Ensure we only map placements if selectedChannel is found
		const selectedChannel = availableChannels.find(
			(channel) => channel.id === location.state?.selectedChannel,
		);

		// Only set placements if selectedChannel and its placements exist
		if (selectedChannel && selectedChannel.placements) {
			const placements = selectedChannel.placements.map(
				(placement: any) => placement.id,
			);
			formMethods.setValue('placements', placements);
		} else {
			formMethods.setValue('placements', []); // Clear placements if no selected channel or placements found
		}

		handleFieldChanges();
	}, [selectedChannels, availableChannels]);

	useEffect(() => {
		if (
			campaignId === 'new' &&
			availableChannels.length &&
			!isChannelsSelected
		) {
			setIsChannelsSelected(true);
			const defaultPlacements = availableChannels.flatMap(
				(channel) =>
					channel.placements?.map((placement: any) => placement.id) || [],
			);
			formMethods.setValue('placements', defaultPlacements);

			const channelObj = availableChannels.reduce(
				(acc, channel) => ({
					...acc,
					[channel.id]: true,
				}),
				{},
			);
			// formMethods.setValue('channels', channelObj);
		}
	}, [campaignId, availableChannels]);

	const { config, isLoading: isConfigLoading } = useCampaignConfig();

	const handleGoalSelect = (goal: IOption) => {
		formMethods.setValue('goalsList', [...goalsList, goal.value]);
	};

	const handleMetaValueChange = (value: IOption['value'][] | IOption[]) => {
		if (typeof value[0] === 'string') return;
		formMethods.setValue('destination', value[0]?.url ?? '');
	};

	const popularGoals = (config?.goals ?? []).slice(0, 3);
	const filterePopularGoals = popularGoals.filter(
		(goal) => !goals.find((g) => g.id === goal.value),
	);
	const handleSubmit = formMethods.handleSubmit(async () => {
		await delay(1000);
		await onCampaignSubmit(Number(watchedBudgetTotal));
	});

	const showSubmitButton = !isSubmitting && !campaign?.brief;

	const {
		data: products,
		refetch: refetchProducts,
		isLoading: isProductsLoading,
	} = useFetch<ICatalog[]>(() => getAccountConfigOptions('catalogs'));

	const handleCreateProduct = async (payload: ICatalogForm) => {
		try {
			const createdCatalog = await createAccountConfigOptions(
				'catalogs',
				payload,
			);
			refetchProducts();
			formMethods.setValue('promotedObject.value', [
				{
					value: createdCatalog._id,
					label: payload.name,
				},
			]);
			formMethods.setValue('destination', payload.url);
		} catch (error: any) {
			toastError(error);
		}
	};

	const handleCreateNewMeta = (val: string) => {
		const defaultInitialValues = {
			kind: 'service',
			name: val,
			url: '',
			details: {},
			category: undefined,
			tags: [],
		};

		switch (promotedObjectType) {
			case ECampaignPromotedType.catalogs:
				formProductToggle.onOpen(defaultInitialValues);
				break;
			default:
				break;
		}
	};

	const getMetaOptions = () => {
		if (promotedObjectType === ECampaignPromotedType.catalogs) {
			return (products || []).map((product) => ({
				label: product.name,
				value: product.id,
				url: product.url,
			}));
		}
	};

	const handleCreateToneOption = async (name: string) => {
		if (!name.trim()) {
			formToneToggle.onOpen();
			return;
		}

		await handleCreateTone({ name });
	};

	const handleCreateTone = async (payload: IAccountConfigOption) => {
		try {
			const newTone = await createTone(payload);
			formMethods.setValue('tone', [...(watchedTones || []), newTone.id]);
		} catch (error: any) {
			toastError(error);
		}
	};

	const handleCreateAudienceOption = async (name: string) => {
		if (!name.trim()) {
			formAudienceToggle.onOpen();
			return;
		}

		await handleCreateAudience({ name });
	};

	const handleCreateAudience = async (payload: IAccountConfigOption) => {
		try {
			const newAudience = await createAudience(payload);
			formMethods.setValue('audience', [
				...(watchedAudience || []),
				newAudience.id,
			]);
		} catch (error: any) {
			toastError(error);
		}
	};

	const handleRemoveGoal = (val: IOption) => {
		formMethods.setValue(
			'goalsList',
			goalsList.filter((v) => v !== val.value),
		);
	};

	const isMetaOptionsLoading = isProductsLoading;
	const CAMPAIGN_META_OPTIONS = useMemo(
		() => getMetaOptions(),
		[isMetaOptionsLoading, promotedObjectType],
	);

	useEffect(() => {
		onMetaOptionsChange(CAMPAIGN_META_OPTIONS ?? []);
	}, [CAMPAIGN_META_OPTIONS]);

	useEffect(() => {
		if (!goalsList || goalsList.length === goals.length) return;
		const newGoals = (goalsList ?? []).map((g) => {
			const goal = goals.find((goal) => goal.id === g);
			return {
				id: g,
				target: goal?.target ?? 0,
				// title: goal?.title ?? 'Target',
			};
		});

		formMethods.setValue('goal', newGoals);
	}, [goalsList]);

	const queryStatus = searchParams.get('status');

	useEffect(() => {
		if (queryStatus === 'draft' && !isCampaignLoaded) {
			setIsCampaignLoaded(true);
			navigate(`/projects/campaigns/${campaignId}`, { replace: true });
			return;
		}
		if (
			campaign &&
			campaign.promotedObject &&
			!isMetaOptionsLoading &&
			!isCampaignLoaded
		) {
			setIsCampaignLoaded(true);
			const {
				title,
				description,
				goal,
				promotedObject,
				destination,
				audience,
				tone,
				promotion,
				channels,
				budget,
			} = transformCampaignToForm(campaign, CAMPAIGN_META_OPTIONS ?? []);

			const newGoalsList = goal.map((g) => g.id);
			formMethods.reset({
				title,
				description,
				goalsList: newGoalsList,
				goal,
				promotedObject,
				destination,
				audience,
				tone,
				promotion,
				channels,
				budget,
			});
		}
	}, [campaign, queryStatus, isMetaOptionsLoading]);

	useEffect(() => {
		if (campaign?.budget.total) {
			if (watchedBudgetTotal !== String(campaign.budget.total)) {
				formMethods.setValue('budget.total', String(campaign.budget.total));
			}
			formMethods.setValue(
				'budget.breakdown',
				campaign?.budget.breakdown ?? [],
			);
		}
	}, [campaign?.budget]);

	useEffect(() => {
		if (
			campaign &&
			campaign.promotedObject &&
			!isMetaOptionsLoading &&
			!isPromotedObjectLoaded
		) {
			const { promotedObject, destination } = transformCampaignToForm(
				campaign,
				CAMPAIGN_META_OPTIONS ?? [],
			);
			if (promotedObject.type === promotedObjectType) {
				setIsPromotedObjectLoaded(true);
				formMethods.setValue('promotedObject.value', promotedObject.value);
				formMethods.setValue('destination', destination);
			}
		}
	}, [campaign, CAMPAIGN_META_OPTIONS]);

	const promotedObjectOptions = useMemo(() => getPromotedObjectOptions(), []);

	const autosave = useAutoSaveCampaign();

	useEffect(() => {
		const isNewCampaign = campaignId && campaignId === 'new';
		const isOldCampaignLoaded = Boolean(campaign && isCampaignLoaded);
		const shouldSaveDelta = isNewCampaign || isOldCampaignLoaded;
		if (!shouldSaveDelta || !group) return;

		handleFieldChanges();
	}, [
		promotedObjectType,
		watchedPromotedObjectValue,
		watchedDestination,
		watchedAudience,
		watchedTones,
		watchedPromotion,
		watchedGoals.length,
		// watchedChannels.length,
		group,
	]);

	const handleFieldChanges = async () => {
		autosave.onFieldsChange({ ...formMethods.getValues(), group });
	};

	const setFieldValue = (field: any, value: string) => {
		formMethods.setValue(field, value);
		handleFieldChanges();
	};

	const handleTitleTabKeyPress = (e: any) => {
		if (e.key === 'Tab') {
			e.preventDefault();
			formMethods.setFocus('description');
		}
	};

	const capitalizeFirstLetter = (value: string) => {
		if (!value) return;
		return value.charAt(0).toUpperCase() + value.slice(1);
	};

	return (
		<Container maxW="7xl" px={0} pb={5} m={0}>
			<FormProvider {...formMethods}>
				<form onSubmit={handleSubmit}>
					<Flex direction="column" gap={7}>
						<Flex gap={1} ref={topElementRef}>
							<Heading fontWeight="bold" fontSize="24px">
								Gather information to generate ads
							</Heading>
						</Flex>
						<StringInputHook
							name="title"
							label="Name"
							requirementsLabel="This is the name that you will use to identify this campaign later. e.g: Christmas campaign"
							placeholder="Enter campaign name"
							inputProps={{
								maxW: '400px',
								onChange: handleFieldChanges,
								onKeyDown: (e: any) => handleTitleTabKeyPress(e),
							}}
							required
						/>
						<TextareaInputHook
							name="description"
							label="Objective"
							requirementsLabel="Describe what you are promoting, to whom and what you want to achieve. The more you put here, the better"
							placeholder="Enter campaign description"
							textareaProps={{ onChange: handleFieldChanges }}
							withAssistant
							setFieldValue={setFieldValue}
						/>
						<Flex direction="column">
							<Flex gap={12}>
								<Box maxWidth="400px">
									<MultiSelectInputHook
										name="goalsList"
										label="What do you want to achieve?"
										requirementsLabel="Select what you are trying to achieve with this campaign. ie: Increase sales"
										placeholder="Select/search"
										options={
											!isConfigLoading && config?.goals ? config.goals : []
										}
										required
									/>
								</Box>
							</Flex>
							<CampaignGoalsList
								name="goal"
								onRemove={handleRemoveGoal}
								options={!isConfigLoading && config?.goals ? config.goals : []}
								inputProps={{ onBlur: handleFieldChanges }}
							/>
						</Flex>
						<Flex gap={12}>
							<Box maxW="400px" minW="400px">
								<MultiSelectCreateableInputHook
									name="audience"
									label="Who are you trying to promote to?"
									requirementsLabel="Select or create the audiences for this campaign"
									placeholder="Select/search"
									options={inputConfig['Audience'] ?? []}
									isLoading={loadingInputs['Audience']}
									onCreateOption={handleCreateAudienceOption}
									isMulti
									required
								/>
								<MultiSelectValuesHook
									name="audience"
									title="Target Audience"
									options={inputConfig['Audience'] ?? []}
								/>
							</Box>
						</Flex>
						<Flex direction="column">
							<Box maxW="400px" minW="400px">
								<MultiSelectCreateableInputHook
									name="tone"
									label="What tone of voice do you want to use?"
									requirementsLabel="Select or create the tone that you want to use for this campaign"
									placeholder="Select/search"
									options={inputConfig['Tone'] ?? []}
									isLoading={loadingInputs['Tone']}
									onCreateOption={handleCreateToneOption}
									isMulti
									required
								/>
							</Box>
							<MultiSelectValuesHook
								name="tone"
								options={inputConfig['Tone'] ?? []}
							/>
						</Flex>
						<Flex flex={1} minW="400px" maxW="400px" direction="column" gap={2}>
							<FormLabel fontSize="sm" fontWeight={500}>
								<Flex gap={1} alignItems="center"></Flex>
							</FormLabel>
							<Box display="none">
								<RadioGroupInputHook
									label="Target"
									name="promotedObject.type"
									defaultValue={
										queryState?.type ?? ECampaignPromotedType.catalogs
									}
									options={promotedObjectOptions}
									required
								/>
							</Box>
						</Flex>
						<Flex direction="column">
							<Flex gap="140px">
								<Box minW="400px" maxW="400px">
									<MultiSelectCreateableInputHook
										name="promotedObject.value"
										label="What are you promoting?"
										requirementsLabel="Select or create a product or a service from your catalog"
										isLoading={isMetaOptionsLoading}
										placeholder="Select/search"
										options={CAMPAIGN_META_OPTIONS || []}
										onValueChangeCallback={handleMetaValueChange}
										onCreateOption={handleCreateNewMeta}
										isMulti={false}
										valueAsObject
										isClearable
										required
									/>
								</Box>

								<InformationBanner title="Garbage in, garbage out">
									<Text>- Upload an image with good quality</Text>
									<Text>
										- The product must be visible with all its details
									</Text>
									<Text>- Ensure your image has no decorations around</Text>
								</InformationBanner>
							</Flex>
							<StringInputEditableHook
								name="destination"
								label="Landing page"
								placeholder="Enter Landing page url"
								formControlProps={{ mt: 2 }}
								inputProps={{ w: '400px' }}
								required
							/>
							<Flex
								flex={1.4}
								pt={isLargerThan1163 ? 14 : 7}
								minW="400px"
								h="80%"
							></Flex>
						</Flex>
						<Box maxW="500px">
							<PromoCodesInputHook
								name="promotion"
								label="Do you want to add a promotional code? (Optional)"
								requirementsLabel="Your ads will show this promotion"
								formLabelProps={{ mb: 1 }}
								valuesContainerProps={{ mt: 0 }}
							/>
						</Box>
						{(isChannelsSelected || campaign) && (
							<MarketingChannelsForm
								availableChannels={availableChannels}
								requirementsLabel="Select the channels that you want to advertise on"
								error={channelsErrors?.root.message}
								selectedChannels={selectedChannels}
							/>
						)}
						<Flex maxW="500px" flexDirection="column">
							<CurrencyInputHook
								name="budget.total"
								label="How much do you want to spend on this campaign?"
								requirementsLabel="Indicate the total amount that you are willing to spend across the selected channels during the total duration"
								placeholder="$0"
								inputProps={{ onChange: handleFieldChanges }}
								required
							/>
						</Flex>
						<Box
							display={showSubmitButton ? 'block' : 'none'}
							textAlign="center"
						>
							<Button
								id="campaign-config-form-submit"
								colorScheme="secondary"
								fontWeight="medium"
								type="submit"
							>
								Generate Content
							</Button>
						</Box>
					</Flex>
				</form>
			</FormProvider>

			<ProductFormModal
				isOpen={formProductToggle.isOpen}
				catalogKinds={catalogKinds}
				onClose={formProductToggle.onClose}
				onSubmit={handleCreateProduct}
				title="What are you promoting?"
				initialValues={formProductToggle.payload}
				// categoriesOptions={(categories || []).map((category) => ({
				//     label: category.name,
				//     value: category.id,
				// }))}
				// brandsOptions={(brands || []).map((brand) => ({
				//     label: brand.name,
				//     value: brand.id,
				// }))}
				// onRefetchBrands={refetchBrands}
				// onRefetchCategories={refetchCategories}
			/>
			<ConfigFormModal
				isOpen={formToneToggle.isOpen}
				onClose={formToneToggle.onClose}
				onSubmit={handleCreateTone}
				initialValues={formToneToggle.payload}
				title="Tone"
			/>
			<ConfigFormModal
				isOpen={formAudienceToggle.isOpen}
				onClose={formAudienceToggle.onClose}
				onSubmit={handleCreateAudience}
				initialValues={formAudienceToggle.payload}
				title="Audience"
			/>
		</Container>
	);
};

export default CampaignForm;
