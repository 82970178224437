import { useState, useEffect, useMemo, useContext, Dispatch } from 'react';
import { useNavigate } from 'react-router-dom';
import { CampaignContext } from 'src/contexts';
import {
	getCampaignDelta,
	validateInitialPayload,
} from 'src/components/campaign/utils/delta';
import { ICampaign, ICampaignForm } from 'src/lib/schemas';
import { createOrUpdateCampaign } from 'src/services/campaign';
import { toastError } from 'src/services/toast';
import { transformPartialFormToCampaign } from 'src/components/campaigns/utils/transform';
import { IDesignDirection } from 'src/lib/schemas/campaign/newFlowCampaign';
import { isEmpty, omit } from 'lodash';

const CAMPAIGN_FIELDS_OMIT = [
	'account',
	'status',
	'brief',
	'creatives',
	'schedule',
	'lastUpdatedBy',
	'updatedAt',
	'createdAt',
];

const useAutoSaveCampaign = (
	setDirtyPlacements: Dispatch<React.SetStateAction<boolean>>,
	setDesignDirections: Dispatch<React.SetStateAction<IDesignDirection[]>>,
) => {
	const {
		campaign,
		id: campaignId,
		setCampaign,
		isLaunching,
	} = useContext(CampaignContext);

	const [changedFields, setChangedFields] = useState<Partial<ICampaign>>({});
	const [isSaving, setIsSaving] = useState(false);
	const [pendingChangesQueue, setPendingChangesQueue] = useState<
		Partial<ICampaign>[]
	>([]);

	const navigate = useNavigate();

	const cleanedCampaign = useMemo(() => {
		return omit(campaign, CAMPAIGN_FIELDS_OMIT);
	}, [campaign]);

	useEffect(() => {
		if (!isSaving && !isEmpty(changedFields)) {
			//@ts-ignore
			setCampaign((prevState) => ({ ...prevState, ...changedFields }));
			handleUpdateCampaign();
		}
	}, [changedFields, isSaving]);

	const isAllFieldsUndefined = (obj: any) => {
		return Object.values(obj).every((value) => value === undefined);
	};

	const handleUpdateCampaign = async () => {
		if (isSaving) return;
		setIsSaving(true);

		try {
			const delta = getCampaignDelta(cleanedCampaign, changedFields);
			const payload = omit(delta, ['id', 'status']);

			// Removes all undefined properties from the payload object.
			Object.keys(payload).forEach((key) => {
				if ((payload as Record<string, unknown>)[key] === undefined) {
					delete (payload as Record<string, unknown>)[key];
				}
			});

			const isEmptyPayload = isEmpty(payload) || isAllFieldsUndefined(payload);
			const isInvalidPayload = validateInitialPayload({
				...changedFields,
				group: undefined,
			});

			if (isEmptyPayload || isInvalidPayload) {
				setIsSaving(false);
				return;
			}

			const response = await createOrUpdateCampaign(
				payload,
				campaignId ?? 'new',
			);

			response.designDirections &&
				setDesignDirections(response.designDirections);

			if (campaignId === 'new') {
				navigate(`/projects/campaigns/${response.id}?status=draft`, {
					replace: true,
				});
			}

			if (payload.placements) {
				setDirtyPlacements(false);
			}
		} catch (error: any) {
			if (error.response) {
				const isTarget = error.response.data.message.includes('target');
				!isTarget && toastError(error);
			} else {
				console.error(error);
			}
		} finally {
			setIsSaving(false);

			if (pendingChangesQueue.length > 0) {
				const nextChanges = pendingChangesQueue[0];
				setPendingChangesQueue((prevQueue) => prevQueue.slice(1));
				setChangedFields(nextChanges);
			}
		}
	};

	const handleFieldsChange = (formData: Partial<ICampaignForm>) => {
		if (isLaunching) return;

		const transformedFormValues = transformPartialFormToCampaign(
			formData,
			campaign,
		);

		if (isSaving) {
			setPendingChangesQueue((prevQueue) => [
				...prevQueue,
				transformedFormValues,
			]);
		} else {
			setChangedFields(transformedFormValues);
		}
	};

	return { onFieldsChange: handleFieldsChange };
};

export default useAutoSaveCampaign;
