import { FC } from 'react';
import {
	Link,
	useLocation,
	useNavigate,
	useSearchParams,
} from 'react-router-dom';
import { Box, Flex, HStack, ListIcon, ListItem, Text } from '@chakra-ui/react';
import 'src/styles/hover.css';
import { IMenuItem } from 'src/services/menu';
import { iconMapper } from 'src/services/iconMapper';

interface NavListItemProps extends IMenuItem {
	shouldRefresh?: boolean;
}

const NavListItem: FC<NavListItemProps> = ({
	label,
	icon,
	url,
	shouldRefresh,
}) => {
	const { pathname } = useLocation();
	const [search] = useSearchParams();
	const navigate = useNavigate();

	const isActiveLink = (link: string) => {
		const [baseLink, searchParam] = link.split('?');
		const isActivePath = pathname.startsWith(baseLink);

		if (searchParam) {
			return isActivePath && search.toString() === searchParam;
		}
		return isActivePath;
	};

	const activeLinkProps = isActiveLink(url ?? '')
		? { color: 'orange.base', bg: '#EDEEEF', borderLeftRadius: 0 }
		: {};

	return (
		<Flex>
			<Box
				borderRight={
					isActiveLink(url ?? '')
						? '3px solid #F7480B'
						: '3px solid transparent'
				}
				borderRadius="0px 4px 4px 0px"
			/>
			<Box w="full">
				<ListItem
					cursor="pointer"
					borderRadius="6px"
					onClick={() => navigate(url ?? '')}
				>
					<HStack
						className="container"
						_hover={{
							color: 'orange.base',
						}}
						p={3}
						pl="20px"
						borderRadius="6px"
						{...activeLinkProps}
					>
						{icon && (
							<ListIcon
								as={iconMapper[icon as keyof typeof iconMapper] ?? (() => null)}
							/>
						)}
						<Text
							className="text"
							fontWeight="500"
							lineHeight="175%"
							letterSpacing="0.2px"
							{...activeLinkProps}
						>
							{label}
						</Text>
					</HStack>
				</ListItem>
			</Box>
		</Flex>
	);
};

export default NavListItem;
