import {
	Box,
	Button,
	VStack,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	HStack,
	Text,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import React, { useContext, useState } from 'react';
import { ReactComponent as AutoAssignIcon } from 'src/assets/svgs/auto-assign-icon.svg';
import { ReactComponent as GalleryIcon } from 'src/assets/svgs/gallery-icon.svg';
import SelectTemplateModal from './SelectTemplateModal';
import TemplateContext from 'src/contexts/templates/TemplatesContext';

interface GenerateOrSelectCardProps {
	onGenerate: () => void;
	campaignId: string;
	handleRefreshDesignDirections: () => Promise<void>;
	setIsGeneratingDD: React.Dispatch<React.SetStateAction<boolean>>;
	isDisabled?: boolean;
	source?: 'campaignForm' | 'designDirections';
}

const GenerateOrSelectCard = ({
	onGenerate,
	handleRefreshDesignDirections,
	setIsGeneratingDD,
	isDisabled = false,
	source = 'designDirections',
}: GenerateOrSelectCardProps) => {
	const [isModalOpen, setModalOpen] = useState(false);
	const handleOpenModal = () => {
		setModalOpen(true);
	};

	const { setSelectedTemplateIds } = useContext(TemplateContext);

	const handleCloseModal = () => {
		setModalOpen(false);
	};

	return (
		<Box>
			<VStack spacing={4} w="full">
				<HStack spacing={0}>
					<Menu placement="bottom-end">
						<MenuButton
							as={Button}
							size="md"
							px={4}
							py={2}
							fontSize="sm"
							variant="orangeSolid"
							borderRightColor={'whiteAlpha.900'}
							rightIcon={
								<ChevronDownIcon
									boxSize={5}
									justifyContent="center"
									alignContent="center"
								/>
							}
							isDisabled={isDisabled}
							opacity={isDisabled ? 0.6 : 1}
							cursor={isDisabled ? 'not-allowed' : 'pointer'}
							pointerEvents={isDisabled ? 'none' : 'auto'}
							_hover={isDisabled ? {} : { opacity: 0.9 }}
						>
							Add design
						</MenuButton>
						<MenuList zIndex="popover">
							<MenuItem
								onClick={onGenerate}
								icon={<AutoAssignIcon width={20} height={20} />}
								isDisabled={isDisabled}
							>
								<Text fontSize="16px" fontFamily="Noto Sans" color="#4B5563">
									Auto assign design
								</Text>
							</MenuItem>
							<MenuItem
								onClick={handleOpenModal}
								icon={<GalleryIcon width={20} height={20} />}
								isDisabled={isDisabled}
							>
								<Text fontSize="16px" fontFamily="Noto Sans" color="#4B5563">
									Select from the gallery
								</Text>
							</MenuItem>
						</MenuList>
					</Menu>
				</HStack>
			</VStack>
			{isModalOpen && (
				<SelectTemplateModal
					isOpen={isModalOpen}
					onClose={handleCloseModal}
					header="Template Gallery"
					size="80%"
					handleRefreshDesignDirections={handleRefreshDesignDirections}
					isTemplateModal={true}
					setIsGeneratingDD={setIsGeneratingDD}
					source={source}
					onSubmit={source === 'campaignForm' ? onGenerate : undefined}
				/>
			)}
		</Box>
	);
};

export default GenerateOrSelectCard;
