import { ICampaign, ECampaignPromotedType } from './index';

export const CHANNELS = [
	'facebook',
	'facebookPaid',
	'instagram',
	'instagramPaid',
	'twitter',
	'google',
	'sms',
	'email',
];

export const PLACEMENTS = [
	'facebookFeedSocialMedia',
	'facebookStorySocialMedia',
	'instagramFeedSocialMedia',
	'instagramStorySocialMedia',
	'facebookFeedPaidAd',
	'facebookStoryPaidAd',
	'instagramFeedPaidAd',
	'instagramStoryPaidAd',
	'twitterSocialMedia',
	'googlePMAX',
	'smsDirectMarketing',
	'emailDirectMarketing',
];

export const defaultCampaign: ICampaign = {
	id: '',
	group: '',
	status: 'draft',
	title: '',
	description: '',
	destination: '',
	audience: [],
	tone: [],
	promotedObject: {
		type: ECampaignPromotedType.landingPage,
		ids: [],
	},
	promotion: [],
	channels: [],
	budget: {
		currency: 'USD',
		total: 0,
		breakdown: [],
	},
	brief: undefined,
	designDirections: [],
	creatives: [],
	schedule: undefined,
	lastUpdatedBy: {
		name: '',
		email: '',
		role: '',
		permissions: [],
		isEmailVerified: false,
		account: '',
		isInvited: false,
		id: '',
	},
	updatedAt: new Date(),
	placements: PLACEMENTS,
};
