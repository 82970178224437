import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Button,
	Divider,
	Flex,
	FormControl,
	Heading,
	Text,
	Image,
	FormErrorMessage,
} from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate } from 'react-router-dom';

import {
	StringInputHook,
	FileInput,
	SelectSearchInputHook,
} from '../common/form';
import FusionLoading from '../common/FusionLoading';
import AccountSettingsModal from './AccountSettingsModal';
import AlertMessage from '../common/AlertMessage';
import UserContext from 'src/contexts/UserContext';
import { CompanyAccountSchema, ICompanyAccountForm } from 'src/lib/schemas';
import { getCompanyAccount, updateCompanyAccount } from 'src/services/account';
import useAccountConfig from 'src/hooks/useAccountConfig';
import useToggleWithPayload from 'src/hooks/useToggleWithPayload';
import { toastSuccess } from 'src/services/toast';
import { IOption } from 'src/lib/schemas/misc';
import MultiLevelDropdown from '../common/MultiLevelDropdown';
import { IIndustry, getIndustries } from 'src/services/industries';
import { omit } from 'lodash';
import { validateWebsiteUrl } from 'src/lib/utils';
import { uploadFile } from 'src/services/fileUpload';
import { FileRejection } from 'react-dropzone';
import TemplateContext from 'src/contexts/templates/TemplatesContext';

const IMAGE_NOT_VALID_MESSAGE = 'Invalid image';

const CompanyAccountForm = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const { user, account, setAccount } = useContext(UserContext);
	const { config, isLoading: isConfigLoading } = useAccountConfig();
	const [industries, setIndustries] = useState<IIndustry[] | null>(null);
	const [loadingIndustries, setLoadingIndustries] = useState(true);
	const [isLogoLoading, setIsLogoLoading] = useState(false);
	const [customOptions, setCustomOptions] = useState<IOption[]>([]);
	const settingsModalToggle = useToggleWithPayload();
	const navigate = useNavigate();
	const { setSelectedIndustry, fetchTemplatesByScope } =
		useContext(TemplateContext);

	useEffect(() => {
		const elements = document.querySelectorAll('.chakra-container');
		elements.forEach((element) => {
			(element as HTMLElement).style.minWidth = '100%';
		});
	}, []);

	const fetchIndustries = async () => {
		setLoadingIndustries(true);
		const response = await getIndustries();
		response && setIndustries(response);
		setLoadingIndustries(false);
	};

	useEffect(() => {
		fetchIndustries();
	}, []);

	const formMethods = useForm<ICompanyAccountForm>({
		resolver: zodResolver(CompanyAccountSchema),
	});
	const {
		handleSubmit,
		reset,
		formState,
		watch,
		setValue,
		clearErrors,
		setFocus,
		setError: setFieldError,
	} = formMethods;

	const fetchCompanyAccount = async () => {
		setIsLoading(true);
		try {
			if (!user) {
				setIsLoading(false);
				return;
			}

			const data = await getCompanyAccount(user.account);
			const { timezone, language, brandIdentity, ...rest } = data;
			const defaultTimezone =
				timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;
			const defaultLanguage = language ?? 'English';

			const brandIdentityInfo = brandIdentity
				? { brandIdentity: { fontType: brandIdentity.fontType } }
				: {};

			reset({
				...rest,
				account: user.account,
				timezone: defaultTimezone,
				language: defaultLanguage,
				...brandIdentityInfo,
			});
		} catch (error) {
			reset({
				timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
				language: 'English',
			});
			setError('Failed to fetch company account');
		}
		setIsLoading(false);
	};

	useEffect(() => {
		if (!user || isLoading) return;

		fetchCompanyAccount();
		reset({ account: user.account });
	}, [user]);

	const industry = watch('industry');
	const websiteLink = watch('websiteLink');
	const logo = watch('logo');
	const fontType = watch('brandIdentity.fontType');
	const logoHasError = formState.errors['logo'];
	const memoizedLogoError = useMemo(
		() => logoHasError?.message,
		[logoHasError],
	);

	useEffect(() => {
		if (memoizedLogoError !== undefined) {
			setFieldError('logo', { message: memoizedLogoError });
		}
	}, [memoizedLogoError, setFieldError]);

	const loadCustomFont = async () => {
		if (fontType) {
			const customFont = new FontFace('CustomFont', `url(${fontType})`);
			await customFont.load();
			document.fonts.add(customFont);
			const family = customFont.family;
			const isFontExists = config?.fonts.find((f) => f.value === fontType);
			if (!isFontExists) {
				setCustomOptions([{ value: fontType, label: family }]);
			} else {
				setCustomOptions([]);
			}
			document.fonts.add(customFont);
		}
	};

	useEffect(() => {
		loadCustomFont();
	}, [fontType]);

	const handleWebsiteLinkChange = (e: any, updateValue = false) => {
		const url = e.target.value;
		const { isValid, updatedUrl } = validateWebsiteUrl(url);
		if (!isValid) {
			setFieldError('websiteLink', { message: 'Please enter a valid URL' });
			return;
		}
		if (updateValue) {
			setValue('websiteLink', updatedUrl);
		}
		clearErrors('websiteLink');
	};

	const handleFontChange = async (
		input: File | string,
		fileRejections?: FileRejection[],
	) => {
		if (fileRejections && fileRejections.length > 0) {
			console.error('File rejected', fileRejections);
			onImageError('File type not accepted');
			return;
		}
		const newFont = typeof input === 'string' ? input : (input as any).path;
		setValue('brandIdentity.fontType', newFont);
	};

	const handleLogoChange = async (
		input: File | string,
		fileRejections?: FileRejection[],
	) => {
		const minWidth = 100;
		const minHeight = 30;

		if (fileRejections && fileRejections.length > 0) {
			console.error('File rejected', fileRejections);
			onImageError('File type not accepted');
			return;
		}

		setIsLogoLoading(true);
		try {
			const { imageUrl } = await uploadFile(input, true, minWidth, minHeight);
			setIsLogoLoading(false);
			clearErrors('logo');
			setValue('logo', imageUrl);
		} catch (error: any) {
			console.error('Error uploading file', error);
			onImageError(error.response?.data?.message || IMAGE_NOT_VALID_MESSAGE);
			setIsLogoLoading(false);
		}
	};

	const handleTypographyChange = (url: string) => {
		setValue('brandIdentity.fontType', url);
	};

	const handleIndustryChange = (industry: IIndustry) => {
		setValue('industry', omit(industry, 'children', 'parentId', '_id'));
	};

	// useEffect(() => {
	//const fontsAvailable = !isConfigLoading && config?.fonts;
	//if (!fontType && fontsAvailable && !initialFormLoaded) {
	//const defaultFont = config.fonts.find((f) => f.label === 'Verdana');
	//if (defaultFont) setValue('brandIdentity.fontType', defaultFont.value);
	//setIsInitialFormLoaded(true);
	//}
	// }, [account, isConfigLoading, config, fontType]);

	const onImageError = (message?: string) => {
		setFieldError('logo', { message });
		console.error('eror', message);
	};

	const handleConfirmModal = () => {
		navigate('/projects/campaigns/new');
	};

	const onSubmit = handleSubmit(async (formData) => {
		if (!user) return;
		let updatedFormData = omit(formData, 'id');
		if (industry) {
			const updatedIndustry = omit(industry, '_id', 'children');
			updatedFormData = { ...formData, industry: updatedIndustry };
		}
		if (logoHasError) {
			setFocus('logo');
			return;
		}

		try {
			setError(null);
			const data = await updateCompanyAccount(updatedFormData);
			toastSuccess('Your changes have been saved');
			setSelectedIndustry(industry);
			if (industry) {
				await fetchTemplatesByScope(industry);
			}
			const isValidAccount = Boolean(account?.name);
			if (!isValidAccount) {
				settingsModalToggle.onOpen();
				setTimeout(() => {
					settingsModalToggle.onClose();
				}, 10000);
			}
			setAccount(data);
			// reset({ ...data, account: user.account });
			// data && clearErrors();
		} catch (error: any) {
			const { message } = error.response.data;
			setError(message);
		}
	});

	const isDataLoading = isLoading || isConfigLoading;

	return (
		<>
			<FusionLoading isLoading={isDataLoading} />
			<AccountSettingsModal
				isOpen={settingsModalToggle.isOpen}
				onClose={settingsModalToggle.onClose}
				onConfirm={handleConfirmModal}
			/>
			{!isDataLoading && (
				<>
					<Heading>Company Info</Heading>
					{error && (
						<AlertMessage status="error" my={4}>
							{error}
						</AlertMessage>
					)}
					<FormProvider {...formMethods}>
						<form onSubmit={onSubmit}>
							<Flex direction="column" gap="20px" mt={5} maxW="full">
								<Flex direction="column" gap="20px" maxW="full">
									<StringInputHook
										name="name"
										label="Name"
										placeholder="Name"
										required
									/>
									<StringInputHook
										name="websiteLink"
										label="Website"
										placeholder="Website"
										required
										// error={formState.errors['logo']}
										formControlProps={{
											onBlur: (e) => handleWebsiteLinkChange(e, true),
											onChange: handleWebsiteLinkChange,
										}}
									/>
									<Flex direction="column" gap={5}>
										<FormControl isInvalid={Boolean(logoHasError)}>
											<StringInputHook
												name="logo"
												label="Logo"
												placeholder="Enter Logo URL"
												required
												withErrorMessage={false}
												errorMessage={
													formState.errors['logo']?.message as string
												}
												inputProps={{
													onChange: (e) => handleLogoChange(e.target.value),
												}}
											/>
											<FormErrorMessage>
												{formState.errors['logo']?.message}
											</FormErrorMessage>
											<Flex justify="center" py={2} alignItems="center" gap={2}>
												<Box flex={1} h="1px" bg="gray.100" />
												<Text>OR</Text>
												<Box flex={1} h="1px" bg="gray.100" />
												<Box />
											</Flex>
											<FileInput
												name="logo"
												acceptImages
												uploadButtonText={
													logo ? 'Change image' : 'Upload image'
												}
												onUrlChange={handleLogoChange}
												uploadPath={`${user?.account}/logo`}
												onDrop={(acceptedFiles, fileRejections) =>
													handleLogoChange(acceptedFiles[0], fileRejections)
												}
												onLoading={setIsLogoLoading}
											/>
										</FormControl>
										<FusionLoading isLoading={isLogoLoading} />
										{logo && !logoHasError && !isLogoLoading && (
											<Image
												src={logo}
												alt="Account logo"
												maxW="100px"
												onError={() => onImageError()}
											/>
										)}
									</Flex>
									<Divider />
									<StringInputHook
										name="appStoreLink"
										label="App Store Link"
										placeholder="App Store Link"
									/>
									<StringInputHook
										name="playStoreLink"
										label="Play Store Link"
										placeholder="Play Store Link"
									/>
									{industries && (
										<MultiLevelDropdown
											onSelect={handleIndustryChange}
											items={industries}
											initialValue={industry}
											label="Industry"
											isLoading={loadingIndustries}
										/>
									)}
									<Flex direction="column" gap={5}>
										<FormControl>
											<SelectSearchInputHook
												name="brandIdentity.fontType"
												label="Typography"
												placeholder="Select"
												options={
													!isConfigLoading && config?.fonts ? config.fonts : []
												}
											/>
											<Flex justify="center" py={2} alignItems="center" gap={2}>
												<Box flex={1} h="1px" bg="gray.100" />
												<Text>OR</Text>
												<Box flex={1} h="1px" bg="gray.100" />
												<Box />
											</Flex>
											<FileInput
												name="brandIdentity.fontType"
												acceptFonts
												uploadButtonText="Upload font"
												uploadPath={`${user?.account}/typography`}
												onUrlChange={handleTypographyChange}
												onDrop={(acceptedFiles, fileRejections) =>
													handleFontChange(acceptedFiles[0], fileRejections)
												}
											/>
										</FormControl>
										{fontType && (
											<Text fontFamily="CustomFont" fontSize={18}>
												ABCDEFGHIJKLMNOPQRSTUVWXYZ
												<br />
												abcdefghijklmnopqrstuvwxyz
											</Text>
										)}
									</Flex>
								</Flex>
							</Flex>
							<Divider my={5} mt={8} />
							<Accordion allowMultiple>
								<AccordionItem border="0">
									<AccordionButton padding="0" _hover={{ padding: '0' }}>
										<Box as="span" flex="1" textAlign="left">
											<Heading>Advanced</Heading>
										</Box>
										<AccordionIcon />
									</AccordionButton>
									<AccordionPanel padding="16px 0">
										<Flex gap="32px">
											<SelectSearchInputHook
												name="timezone"
												label="Timezone"
												placeholder="Select"
												options={
													!isConfigLoading && config?.timezones
														? config.timezones
														: []
												}
											/>
											{/* <SelectInputHook
												name="language"
												label="Language"
												placeholder="Select"
												options={
													!isConfigLoading && config?.languages
														? config.languages
														: []
												}
											/> */}
										</Flex>
									</AccordionPanel>
								</AccordionItem>
							</Accordion>
							<Divider my={5} mb={8} />
							<Button
								float="right"
								variant="orangeSolid"
								type="submit"
								isLoading={formState.isSubmitting || isLogoLoading}
								loadingText={
									formState.isSubmitting ? 'Submitting...' : 'Loading...'
								}
							>
								Confirm
							</Button>
						</form>
					</FormProvider>
				</>
			)}
		</>
	);
};

export default CompanyAccountForm;
