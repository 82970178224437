import {
	useState,
	useContext,
	useMemo,
	useRef,
	useEffect,
	RefObject,
	useCallback,
} from 'react';
import { Box } from '@chakra-ui/react';
import CampaignSection from 'src/components/campaigns/CampaignSection';
import SummaryExtraInformation from '../../components/campaigns/SummaryExtraInfo';
import GatherInfoForm from 'src/components/campaigns/GatherInfoForm';
import useAccountConfigOptions from 'src/hooks/config/useAccountConfigOptions';
import { generateDesignDirectionsWithTemplates } from 'src/services/campaign';
import { regenerateCampaignDesignDirection } from 'src/services/campaign';
import {
	AppInputConfigContext,
	CampaignContext,
	withAppConfigProvider,
} from 'src/contexts';
import useCampaignConfig from 'src/hooks/useCampaignConfig';
import { IOption } from 'src/lib/schemas/misc';
import { generateCampaignBrief } from 'src/services/genai';
import { toastError } from 'src/services/toast';
import {
	createOrUpdateCampaign,
	genrateCampaignCreatives,
	getCampaign,
} from 'src/services/campaign';
import UserContext from 'src/contexts/UserContext';
import { getAvailableChannels } from 'src/services/account';
import {
	ICampaign,
	ICampaignSchedule,
	IChannelCreativeAttributes,
	IChannelGroup,
} from 'src/lib/schemas';
import TemplateContext from 'src/contexts/templates/TemplatesContext';
import DesignDirections from 'src/components/campaigns/design-directions/DesignDirections';
import CampaignSchedule from 'src/components/campaigns/schedule/CampaignSchedule';
import { generateBudgetBreakdownByPlacements } from 'src/components/campaign/CampaignBudget/parts/utils';
import CampaignStepper from 'src/components/campaigns/CampaignStepper';
import { withCampaignProvider } from 'src/contexts';
import { validateCampaignConfig } from 'src/components/campaigns/utils/validations';
import FusionLoading from 'src/components/common/FusionLoading';
import { CampaignHeader } from 'src/components/campaign/parts';
import { transformCampaignToGenAIPayload } from 'src/components/campaigns/utils/transform';
import { cloneDeepWith, isEqual, omit } from 'lodash';
import {
	ICreative,
	IDesignDirection,
} from 'src/lib/schemas/campaign/newFlowCampaign';
import { useLocation } from 'react-router-dom';
import { ICampaignNavigationEvent, IEvent } from 'src/lib/schemas/event/event';

interface CustomWindow extends Window {
	fusion?: any;
}
declare const window: CustomWindow;

const CreateEditCampaign = () => {
	const {
		campaign,
		id: campaignId,
		setCampaign,
		enabledSections,
		openSections,
		setOpenSections,
		oldCampaignCopy,
		setOldCampaignCopy,
	} = useContext(CampaignContext);
	const { config } = useCampaignConfig();
	const [group, setGroup] = useState<string | undefined>();
	const [campaignTitle, setCampaignTitle] = useState('');
	const [metaOptions, setMetaOptions] = useState<IOption[]>([]);
	const [availableChannelGroups, setAvailableChannelGroups] = useState<
		IChannelGroup[] | null
	>(null);
	const [availableCreatives, setAvailableCreatives] =
		useState<IChannelCreativeAttributes[]>();
	const [isLoadingChannels, setIsLoadingChannels] = useState(false);
	const [isGeneratingBrief, setIsGeneratingBrief] = useState(false);
	const [designDirections, setDesignDirections] = useState(
		campaign?.designDirections ?? ([] as Array<IDesignDirection>),
	);
	const [creatives, setCreatives] = useState(
		campaign?.creatives ?? ([] as Array<ICreative>),
	);
	const [isGeneratingDesignDirections, setIsGeneratingDesignDirections] =
		useState(false);

	const [isLoading, setIsLoading] = useState(true);
	const designDirectionsRef = useRef<HTMLDivElement>(null);
	const gatherInfoRef = useRef<HTMLDivElement>(null);
	const scheduleRef = useRef<HTMLDivElement>(null);
	const { inputConfig } = useContext(AppInputConfigContext);

	const { account } = useContext(UserContext);
	const {
		selectedTemplateIds,
		setSelectedTemplateIds,
		setSelectTemplateModalOpen,
	} = useContext(TemplateContext);

	const tonesOptions = inputConfig['Tone'] ?? [];
	const audienceOptions = inputConfig['Audience'] ?? [];
	const currentOpenSectionIndex = openSections.findIndex((isOpen) => isOpen);
	const timeoutId = useRef<any>(null);
	const { fetchConfig: fetchTones } = useAccountConfigOptions('Tone');
	const { fetchConfig: fetchAudiences } = useAccountConfigOptions('Audience');
	const [hasLoadedInitialPlacements, setHasLoadedInitialPlacements] =
		useState(false);
	const [selectedPlacements, setSelectedPlacements] = useState<string[]>([]);
	const [selectedChannels, setSelectedChannels] = useState<string[]>([]);
	const [forcedOpenSections, setForcedOpenSections] = useState<
		boolean[] | undefined
	>(undefined);
	const [forcedActiveSection, setForcedActiveSection] = useState<
		number | undefined
	>(undefined);
	const isDesignDirectionPending = designDirections?.some(
		(c) => c.status === 'pending' || c.status === 'generating',
	);

	const isCreativesPending = creatives?.some(
		(c) => c.status === 'pending' || c.status === 'generating',
	);

	const compareCampaigns = (
		newCampaign: any,
		oldCampaign: any,
		fieldsToOmit: string[],
	) => {
		const newCampaignCleaned = omit(newCampaign, fieldsToOmit);
		const oldCampaignCleaned = omit(oldCampaign, fieldsToOmit);
		return isEqual(newCampaignCleaned, oldCampaignCleaned);
	};

	useEffect(() => {
		if (!timeoutId.current) {
			if (isDesignDirectionPending) {
				handleRefetchDesignDirections();
			}
			if (isCreativesPending) {
				handleRefetchCreatives();
			}
		}

		return () => clearTimeout(timeoutId.current);
	}, [isDesignDirectionPending, isCreativesPending]);

	useEffect(() => {
		fetchTones();
		fetchAudiences();
		return () => {
			setSelectedTemplateIds([]);
		};
	}, []);

	const handleRefetchDesignDirections = async () => {
		try {
			if (!campaignId || campaignId === 'new') return;

			const response = await getCampaign(campaignId);

			response.designDirections &&
				setDesignDirections(response.designDirections);

			const pendingDesignDirections = response.designDirections?.some(
				(d) => d.status === 'pending' || d.status === 'generating',
			);

			if (pendingDesignDirections) {
				timeoutId.current = setTimeout(handleRefetchDesignDirections, 1000);
			} else {
				clearTimeout(timeoutId.current);
				timeoutId.current = undefined;
			}
		} catch (error: any) {
			toastError(error);
			clearTimeout(timeoutId.current);
		}
	};

	const handleRefetchCreatives = async () => {
		try {
			if (!campaignId || campaignId === 'new') return;

			const response = await getCampaign(campaignId);

			response.creatives && setCreatives(response.creatives);

			const pendingCreatives = response.creatives?.some(
				(c) => c.status === 'pending' || c.status === 'generating',
			);

			if (pendingCreatives) {
				timeoutId.current = setTimeout(handleRefetchCreatives, 1000);
			} else {
				clearTimeout(timeoutId.current);
				timeoutId.current = undefined;
			}
		} catch (error: any) {
			toastError(error);
			clearTimeout(timeoutId.current);
		}
	};

	useEffect(() => {
		const processEvent = (event: IEvent<any>) => {
			if (event.target == 'campaign') {
				if (event.action == 'setNavigation') {
					const navigationEvent = event as IEvent<ICampaignNavigationEvent>;
					const sections = navigationEvent.value.sections;
					const activeSection = navigationEvent.value.activeSection;
					setForcedOpenSections(sections);
					setForcedActiveSection(activeSection);
				}
			}
		};

		if (window.fusion && Array.isArray(window.fusion)) {
			while (window.fusion.length) {
				processEvent(window.fusion.pop());
			}
		}
		window.fusion = {
			push: processEvent,
		};
	}, []);

	useEffect(() => {
		if (
			!campaign?.placements &&
			campaignId === 'new' &&
			availableChannelGroups?.length
		) {
			const defaultPlacements = availableChannelGroups.flatMap((group) =>
				group.channels.flatMap(
					(channel) =>
						channel.placements?.map((placement) => placement.id) || [],
				),
			);
			setSelectedPlacements(defaultPlacements);
		}

		const highestEnabledIndex = enabledSections
			.map((enabled, index) => (enabled ? index : -1))
			.reduce((max, current) => (current > max ? current : max), -1);

		if (forcedOpenSections) {
			setOpenSections(forcedOpenSections);
		} else {
			setOpenSections((prev) =>
				prev.map((_, index) => index === highestEnabledIndex),
			);
		}

		const selectedIndex = forcedActiveSection ?? highestEnabledIndex;
		switch (selectedIndex) {
			case 1:
				scrollIntoRef(designDirectionsRef);
				break;
			case 2:
				scrollIntoRef(scheduleRef);
				break;
			default:
				break;
		}
	}, [
		availableChannelGroups,
		campaignId,
		forcedOpenSections,
		forcedActiveSection,
	]);

	useEffect(() => {
		if (campaign && !hasLoadedInitialPlacements) {
			if (campaign.placements) {
				setSelectedPlacements(campaign.placements);
			}
			if (campaign.group) {
				setGroup(campaign.group);
			}
			setHasLoadedInitialPlacements(true);
		}
	}, [campaign, hasLoadedInitialPlacements]);

	useEffect(() => {
		const getChannels = async () => {
			if (!account?.id) return;
			setIsLoadingChannels(true);
			const { channels, creatives } = await getAvailableChannels(account.id);
			setAvailableChannelGroups(channels);
			setAvailableCreatives(creatives);
			setIsLoadingChannels(false);
		};
		getChannels();
	}, [account]);

	const availablePlacements = useMemo(
		() =>
			(availableChannelGroups || []).flatMap(
				(channelGroup) => channelGroup.channels,
			),
		[availableChannelGroups],
	);

	const handleCampaignSubmit = async () => {
		if (!campaignId || !campaign) return;
		setIsGeneratingBrief(true);
		setIsGeneratingDesignDirections(true);

		try {
			const genAIPayload = transformCampaignToGenAIPayload(
				{ ...campaign },
				config,
				metaOptions,
				tonesOptions,
				audienceOptions,
			);
			scrollIntoRef(designDirectionsRef);
			const brief = await generateCampaignBrief(genAIPayload);
			const updatedCampaign = await createOrUpdateCampaign(
				{ brief },
				campaignId,
			);
			setCampaign(updatedCampaign);
			setOldCampaignCopy(updatedCampaign);
			setIsGeneratingBrief(false);
			await handleGenerateDesignDirections();
			setIsGeneratingDesignDirections(false);
			setSelectedTemplateIds([]);
			// resetSelectedTemplate();
		} catch (error: any) {
			toastError(error);
			setIsGeneratingBrief(false);
			setIsGeneratingDesignDirections(false);
		}
	};

	const handleRegenerateDesignDirections = async () => {
		if (!campaignId) return;
		scrollIntoRef(designDirectionsRef);

		for (const designDirection of designDirections ?? []) {
			if (!designDirection.locked) {
				try {
					await regenerateCampaignDesignDirection(
						campaignId,
						designDirection.id,
						{},
					);
				} catch (error) {
					console.error(
						`Error regenerating design direction ${designDirection.id}:`,
						error,
					);
				}
			}
		}
		const response = await getCampaign(campaignId);
		response.designDirections && setDesignDirections(response.designDirections);
		response.creatives && setCreatives(response.creatives);
	};

	const handleScheduleAndPublish = async () => {
		if (!campaignId) return;

		setOpenSections([false, false, true, false]);
		scrollIntoRef(scheduleRef);

		try {
			await genrateCampaignCreatives(campaignId, '', true);
			const data = await getCampaign(campaignId);
			setCampaign(data);
		} catch (error) {
			console.error('Error generating creatives:' + error);
		}
	};

	const selectedTemplateIdsRef = useRef<string[]>([]);

	useEffect(() => {
		selectedTemplateIdsRef.current = selectedTemplateIds;
	}, [selectedTemplateIds]);

	const handleGenerateDesignDirections = async () => {
		if (!campaignId) return;
		try {
			await generateDesignDirectionsWithTemplates(
				campaignId,
				selectedTemplateIdsRef.current,
			);
			const data = await getCampaign(campaignId);
			data.designDirections && setDesignDirections(data.designDirections);
		} catch (error: any) {
			toastError(error);
		}
	};

	const scrollIntoRef = (
		ref: RefObject<HTMLDivElement>,
		offset: number = 400,
	) => {
		if (!ref || !ref.current) return;
		const elementPosition =
			ref.current.getBoundingClientRect().top + window.scrollY;
		const offsetPosition = elementPosition - offset;
		setTimeout(() => {
			window.scrollTo({
				top: offsetPosition,
				behavior: 'smooth',
			});
		}, 50);
	};

	const toggleSection = (index: number) => {
		setOpenSections((prev) => {
			const isCurrentlyOpen = prev[index];
			if (isCurrentlyOpen) {
				const newSections = [...prev];
				newSections[index] = false;
				return newSections;
			} else {
				const newSections = prev.map((_, idx) => idx === index);
				return newSections;
			}
		});
	};

	const previousPlacementsRef = useRef(campaign?.placements);
	const hasFirstUpdateOccurred = useRef(false);

	useEffect(() => {
		if (
			JSON.stringify(previousPlacementsRef.current) !==
			JSON.stringify(campaign?.placements)
		) {
			if (!hasFirstUpdateOccurred.current) {
				hasFirstUpdateOccurred.current = true;
			} else {
				updateCampaignBudget();
			}

			previousPlacementsRef.current = campaign?.placements;
		}
	}, [campaign?.placements]);

	const updateCampaignBudget = useCallback(
		async (schedule?: ICampaignSchedule) => {
			if (!campaignId || !campaign) return;

			try {
				const initialBreakdown = generateBudgetBreakdownByPlacements(
					campaign.placements ?? [],
					availableChannelGroups ?? [],
					campaign.budget ? campaign.budget.total : 0,
				);

				const updateData: Partial<ICampaign> = {
					budget: { ...campaign.budget, breakdown: initialBreakdown },
				};

				if (schedule) {
					updateData['schedule'] = schedule;
				}

				createOrUpdateCampaign(updateData, campaignId);
			} catch (error: any) {
				toastError(error);
			}
		},
		[campaignId, campaign, availableChannelGroups, setCampaign],
	);

	const handleSubmitSchedule = (schedule: ICampaignSchedule) => {
		updateCampaignBudget(schedule);
	};

	const handleOpenSection = (section: number) => {
		const fieldsToOmit = [
			'createdAt',
			'creatives',
			'designDirections',
			'lastUpdatedBy',
			'updatedAt',
		];

		const isStepOneOpen =
			openSections.length === 4 &&
			openSections.every(
				(value, index) => value === [true, false, false, false][index],
			);

		if (compareCampaigns(campaign, oldCampaignCopy, fieldsToOmit)) {
			toggleSection(section);
		} else {
			if (!isStepOneOpen) {
				toggleSection(section);
				return;
			}
			const configFormSubmitElement = document.getElementById(
				'campaign-config-form-submit-1',
			);
			configFormSubmitElement?.click();
		}
	};

	useEffect(() => {
		if (!campaignId || campaignId === 'new') return;
		const fetchCampaign = async () => {
			try {
				const oldCampaignCopy = await getCampaign(campaignId!);
				setOldCampaignCopy(cloneDeepWith(oldCampaignCopy));
			} catch (error) {}
		};

		fetchCampaign();
	}, [campaignId, openSections]);

	useEffect(() => {
		const loadData = async () => {
			try {
				await fetchTones();
				await fetchAudiences();
				if (campaignId && campaignId !== 'new') {
					await getCampaign(campaignId);
				}
				if (account?.id) {
					setIsLoadingChannels(true);
					const { channels, creatives } = await getAvailableChannels(
						account.id,
					);
					setAvailableChannelGroups(channels);
					setAvailableCreatives(creatives);
					setIsLoadingChannels(false);
				}
			} catch (error) {
				toastError(error);
			} finally {
				setIsLoading(false);
			}
		};

		loadData();
	}, [account, campaignId]);

	const handleMetaOptions = useCallback((options: IOption[]) => {
		if (options.length) {
			setMetaOptions(options);
		}
	}, []);

	const isValidCampaignConfig = useMemo(() => {
		if (!campaign) return false;
		return validateCampaignConfig(campaign, metaOptions ?? []);
	}, [campaign, metaOptions]);

	const onChannelsChange = (channels: string[]) => {
		setSelectedChannels(channels);
	};

	return (
		<Box p={4} pt="86px" w="full" maxW="1400px" mx="auto">
			<CampaignHeader
				campaignDetail={{
					title: !campaignTitle ? 'New Campaign' : campaignTitle,
					group,
					lastEditTime: campaign?.updatedAt,
					lastEditBy: campaign?.lastUpdatedBy?.name,
				}}
				onGroupChange={(value) => setGroup(value)}
			/>
			<CampaignStepper
				handleOpenSection={handleOpenSection}
				designDirectionsRef={designDirectionsRef}
				scheduleRef={scheduleRef}
				scrollIntoRef={scrollIntoRef}
				currentOpenSectionIndex={currentOpenSectionIndex}
			/>

			<FusionLoading isLoading={isLoading} boxProps={{ mt: 16 }} />
			<Box display={isLoading ? 'none' : 'block'} ref={gatherInfoRef}>
				<CampaignSection
					title="Step 1: Gather Information"
					isOpen={openSections[0]}
					onToggle={() => toggleSection(0)}
					stepIdx={0}
					accordionContent={
						<GatherInfoForm
							group={group}
							availableChannels={availablePlacements}
							availableChannelGroups={availableChannelGroups}
							onCampaignTitleChange={setCampaignTitle}
							onCampaignSubmit={handleCampaignSubmit}
							onMetaOptionsChange={handleMetaOptions}
							setOpenSections={setOpenSections}
							designDirectionsRef={designDirectionsRef}
							selectedPlacements={selectedPlacements}
							onTemplateGalleryOpen={() => setSelectTemplateModalOpen(true)}
							setSelectedPlacements={setSelectedPlacements}
							designDirections={designDirections}
							setDesignDirections={setDesignDirections}
							onRegenerateDesignDirections={handleRegenerateDesignDirections}
							onChannelsChange={setSelectedChannels}
						/>
					}
					extraInformation={
						isGeneratingBrief ? (
							<SummaryExtraInformation />
						) : campaign?.brief ? (
							<SummaryExtraInformation />
						) : undefined
					}
					isDisabled={!enabledSections[0]}
				/>
				<Box ref={designDirectionsRef} />
				<CampaignSection
					title="Step 2: Generate Designs"
					isOpen={openSections[1]}
					stepIdx={1}
					accordionContent={
						<DesignDirections
							creativesConfig={availableCreatives ?? []}
							isLoading={isGeneratingDesignDirections}
							onSubmit={handleScheduleAndPublish}
							designDirections={designDirections}
							setDesignDirections={setDesignDirections}
							creatives={creatives}
							setCreatives={setCreatives}
							availableChannelGroups={availableChannelGroups ?? []}
							selectedChannels={selectedChannels}
						/>
					}
					onToggle={() => handleOpenSection(1)}
					isDisabled={!enabledSections[1]}
				/>
				<Box ref={scheduleRef} />
				<CampaignSection
					title="Step 3: Schedule & Publish"
					isOpen={openSections[2]}
					stepIdx={2}
					accordionContent={
						<CampaignSchedule
							schedule={campaign?.schedule}
							onScheduleSubmit={handleSubmitSchedule}
							availableChannelGroups={availableChannelGroups}
							requirementsLabelStyle={{ color: '#718096', fontSize: '14px' }}
							isValidCampaignConfig={isValidCampaignConfig}
							designDirections={designDirections}
							creatives={creatives}
						/>
					}
					onToggle={() => handleOpenSection(2)}
					isDisabled={!enabledSections[2]}
				/>
			</Box>
		</Box>
	);
};

const CreateEditCampaignWithProvider = withCampaignProvider(CreateEditCampaign);
const CreateEditCampaignWithAppConfigProvider = withAppConfigProvider(
	CreateEditCampaignWithProvider,
);

export default CreateEditCampaignWithAppConfigProvider;
