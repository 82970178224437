import React, {
	useContext,
	useMemo,
	useState,
	useEffect,
	useRef,
	useCallback,
} from 'react';
import {
	Box,
	Button,
	Text,
	useColorModeValue,
	Grid,
	HStack,
	Flex,
	CloseButton,
	Collapse,
	Icon,
	VStack,
} from '@chakra-ui/react';
import { FiChevronDown, FiX } from 'react-icons/fi';
import { motion } from 'framer-motion';
import TemplateContext, {
	ITemplate,
} from 'src/contexts/templates/TemplatesContext';
import CustomModal from 'src/components/common/CustomModal';
import { generateDesignDirectionsWithTemplates } from 'src/services/campaign';
import { toastError } from 'src/services/toast';
import FusionLoading from 'src/components/common/FusionLoading';
import { CampaignContext } from 'src/contexts';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import FilterDropdown from 'src/components/common/FilterDropdown';
interface FilteredTemplates {
	myTemplates: ITemplate[];
	allTemplates: ITemplate[];
	totalMyTemplates: number;
	totalAllTemplates: number;
}

interface SelectTemplateModalProps {
	isOpen: boolean;
	onClose: () => void;
	header?: string;
	handleRefreshDesignDirections?: () => Promise<void>;
	size?: 'full' | any;
	isTemplateModal?: boolean;
	setIsGeneratingDD?: React.Dispatch<React.SetStateAction<boolean>>;
	selectedTemplateIds?: string[];
	source?:
		| 'campaignForm'
		| 'designDirections'
		| 'changeTemplates'
		| 'gallery'
		| 'sidebar';
	onSubmit?: () => void;
}

const MotionBox = motion(Box);

const SelectTemplateModal: React.FC<SelectTemplateModalProps> = ({
	isOpen,
	onClose,
	header = 'Template Gallery',
	size = 'full',
	handleRefreshDesignDirections,
	source,
	onSubmit,
	setIsGeneratingDD,
}) => {
	const {
		templatesByScope,
		templates,
		selectedTemplateIds,
		fetchIndustries,
		templatesLoaded,
		setSelectedTemplateIds,
		industries,
		industriesLoaded,
		fetchTemplatesByScope,
		styles,
		seasons,
	} = useContext(TemplateContext);

	const { id: campaignId, campaign } = useContext(CampaignContext);
	const debounceRef = useRef(false);

	const [scope, setScope] = useState<string>('all');
	const [columns, setColumns] = useState<number>(5);
	const [isExpanded, setIsExpanded] = useState<boolean>(true);
	const [topTemplatesSelected, setTopTemplatesSelected] =
		useState<boolean>(false);
	const [selectedIndustries, setSelectedIndustries] = useState<string[]>([]);
	const [selectedStyles, setSelectedStyles] = useState<string[]>([]);
	const [selectedSeasons, setSelectedSeasons] = useState<string[]>([]);
	const [hasAutoSelectedTopTemplates, setHasAutoSelectedTopTemplates] =
		useState<boolean>(false);
	const [isBeginning, setIsBeginning] = useState<boolean>(true);
	const [isEnd, setIsEnd] = useState<boolean>(false);
	const [hoveredTemplateId, setHoveredTemplateId] = useState<string | null>(
		null,
	);
	const [currentSelectedTemplatesIds, setCurrentSelectedTemplatesIds] =
		useState<string[]>([]);
	const [extendedHoveredTemplateId, setExtendedHoveredTemplateId] = useState<
		string | null
	>(null);
	const [hasSelectedFilters, setHasSelectedFilters] = useState<boolean>(false);
	const [showMyTemplates, setShowMyTemplates] = useState<boolean>(false);
	const isLoading = !templatesLoaded || !industriesLoaded;

	const hoverTimeoutRef = useRef<NodeJS.Timeout | null>(null);
	const swiperRef = useRef<any>(null);

	const navigate = useNavigate();

	const shouldShowTopTemplates = useMemo(() => {
		const hasPendingOrGeneratingDirections =
			campaign?.designDirections?.some(
				(direction) =>
					direction.status === 'pending' || direction.status === 'generating',
			) ?? false;

		return (
			selectedTemplateIds.length === 0 &&
			source === 'campaignForm' &&
			campaign?.designDirections?.length === 0 &&
			!hasPendingOrGeneratingDirections
		);
	}, [selectedTemplateIds, source, campaign?.designDirections]);

	const modalRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (
			(!selectedTemplateIds || selectedTemplateIds.length === 0) &&
			!templatesLoaded
		) {
			fetchTemplatesByScope();
		}

		if (!industriesLoaded) {
			fetchIndustries();
		}

		const handleClickOutside = (event: MouseEvent) => {
			if (
				modalRef.current &&
				!modalRef.current.contains(event.target as Node)
			) {
				handleModalClose();
			}
		};
		if (isOpen) {
			document.addEventListener('mousedown', handleClickOutside);
		} else {
			document.removeEventListener('mousedown', handleClickOutside);
		}

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [
		isOpen,
		selectedTemplateIds,
		templatesLoaded,
		industriesLoaded,
		fetchTemplatesByScope,
		fetchIndustries,
	]);

	const handleTemplateSelect = useCallback((templateId: string) => {
		setCurrentSelectedTemplatesIds((prevSelectedTemplateIds) => {
			if (prevSelectedTemplateIds.includes(templateId)) {
				return prevSelectedTemplateIds.filter((id) => id !== templateId);
			} else {
				return [...prevSelectedTemplateIds, templateId];
			}
		});

		setHoveredTemplateId(null);
		if (hoverTimeoutRef.current) {
			clearTimeout(hoverTimeoutRef.current);
			hoverTimeoutRef.current = null;
		}
		setExtendedHoveredTemplateId(null);
	}, []);

	const handleHoverStart = useCallback((templateId: string) => {
		setHoveredTemplateId(templateId);
		hoverTimeoutRef.current = setTimeout(() => {
			setExtendedHoveredTemplateId(templateId);
		}, 1000);
	}, []);

	const handleHoverEnd = useCallback(() => {
		setHoveredTemplateId(null);
		if (hoverTimeoutRef.current) {
			clearTimeout(hoverTimeoutRef.current);
			hoverTimeoutRef.current = null;
		}
		setExtendedHoveredTemplateId(null);
	}, []);

	const resetState = () => {
		setScope('all');
		setCurrentSelectedTemplatesIds([]);
	};

	const handleGenerateDesign = async () => {
		setSelectedTemplateIds(currentSelectedTemplatesIds);

		if (source === 'gallery') {
			const selectedTemplates = templatesByScope.filter((template) =>
				currentSelectedTemplatesIds.includes(template.id),
			);

			if (currentSelectedTemplatesIds.length > 0) {
				onClose();
				navigate('/projects/campaigns/new');
			}
			return;
		}
		if (source === 'sidebar') {
			navigate('/projects/campaigns/new');
			onClose();
			return;
		}
		if (source === 'changeTemplates') {
			onClose();
			return;
		}

		if (source === 'campaignForm' && onSubmit) {
			onSubmit();
			onClose();
			return;
		}

		if (currentSelectedTemplatesIds.length === 0 || !campaignId) {
			return;
		}

		setIsGeneratingDD && setIsGeneratingDD(true);
		onClose();
		resetState();
		try {
			await generateDesignDirectionsWithTemplates(
				campaignId,
				currentSelectedTemplatesIds,
			);
			handleRefreshDesignDirections && (await handleRefreshDesignDirections());
			handleModalClose();
		} catch (error: any) {
			console.error('Error generating design directions:', error.message);
			toastError('Error generating design directions');
			setIsGeneratingDD && setIsGeneratingDD(false);
		}
	};

	const handleResetAll = () => {
		setSelectedIndustries([]);
		setSelectedStyles([]);
		setSelectedSeasons([]);
		setHasSelectedFilters(false);
		setShowMyTemplates(false);
	};

	useEffect(() => {
		const anyFilterSelected =
			selectedIndustries.length > 0 ||
			selectedStyles.length > 0 ||
			selectedSeasons.length > 0;
		setHasSelectedFilters(anyFilterSelected);
	}, [selectedIndustries, selectedStyles, selectedSeasons]);

	useEffect(() => {
		if (shouldShowTopTemplates && templatesByScope.length > 0) {
			const topTemplates = templatesByScope
				.filter(
					(template) =>
						template.scope === 'platform' || template.scope === 'account',
				)
				.sort((a, b) => (b.score || 0) - (a.score || 0))
				.slice(0, 3)
				.map((template) => template.id);
			setCurrentSelectedTemplatesIds(topTemplates);
			setTopTemplatesSelected(true);
			setHasAutoSelectedTopTemplates(true);
		} else if (!shouldShowTopTemplates) {
			setCurrentSelectedTemplatesIds([]);
		}
	}, [
		isOpen,
		shouldShowTopTemplates,
		templatesByScope,
		hasAutoSelectedTopTemplates,
	]);

	const handleModalClose = () => {
		if (
			source === 'gallery' ||
			source === 'sidebar' ||
			source === 'campaignForm' ||
			source === 'designDirections'
		) {
			setCurrentSelectedTemplatesIds([]);
		}
		setSelectedIndustries([]);
		setSelectedStyles([]);
		setSelectedSeasons([]);
		setHasSelectedFilters(false);
		setShowMyTemplates(false);
		onClose();
	};
	useEffect(() => {
		if (isOpen && source !== 'sidebar' && selectedTemplateIds.length > 0) {
			setCurrentSelectedTemplatesIds(selectedTemplateIds);
		}
	}, [isOpen, selectedTemplateIds]);

	useEffect(() => {
		if (currentSelectedTemplatesIds.length < 3) {
			setTopTemplatesSelected(false);
		}
	}, [currentSelectedTemplatesIds]);

	useEffect(() => {
		if (swiperRef.current) {
			swiperRef.current.slideTo(currentSelectedTemplatesIds.length - 1, 300);
		}
	}, [currentSelectedTemplatesIds]);
	const myAccountTemplatesExist = templates.some(
		(template) => template.scope === 'account',
	);

	useEffect(() => {
		const anyFilterSelected =
			selectedIndustries.length > 0 ||
			selectedStyles.length > 0 ||
			selectedSeasons.length > 0;
		setHasSelectedFilters(anyFilterSelected);
	}, [selectedIndustries, selectedStyles, selectedSeasons]);

	const filteredTemplates: FilteredTemplates = useMemo(() => {
		const applyFilters = (templatesList: ITemplate[]) => {
			return templatesList.filter((template) => {
				const matchesIndustry =
					selectedIndustries.length === 0 ||
					selectedIndustries.some(
						(ind) => template.attributes?.industries?.includes(ind),
					);
				const matchesStyle =
					selectedStyles.length === 0 ||
					(template.attributes?.style &&
						selectedStyles.includes(template.attributes.style));
				const matchesSeason =
					selectedSeasons.length === 0 ||
					(template.attributes?.season &&
						selectedSeasons.includes(template.attributes.season));
				return matchesIndustry && matchesStyle && matchesSeason;
			});
		};

		if (showMyTemplates) {
			const myTemplates = templates.filter(
				(template) => template.scope === 'account',
			);
			const filteredMyTemplates = applyFilters(myTemplates);
			return {
				myTemplates: filteredMyTemplates.filter(
					(template) => !currentSelectedTemplatesIds.includes(template.id),
				),
				allTemplates: [],
				totalMyTemplates: filteredMyTemplates.length,
				totalAllTemplates: 0,
			};
		} else {
			const myTemplates = templates.filter(
				(template) => template.scope === 'account',
			);
			const allTemplates = templatesByScope
				.filter((template) => template.scope === 'platform')
				.sort((a, b) => (b.score || 0) - (a.score || 0));
			const filteredMyTemplates = applyFilters(myTemplates);
			const filteredAllTemplates = applyFilters(allTemplates);
			return {
				myTemplates: filteredMyTemplates.filter(
					(template) => !currentSelectedTemplatesIds.includes(template.id),
				),
				allTemplates: filteredAllTemplates.filter(
					(template) => !currentSelectedTemplatesIds.includes(template.id),
				),
				totalMyTemplates: filteredMyTemplates.length,
				totalAllTemplates: filteredAllTemplates.length,
			};
		}
	}, [
		showMyTemplates,
		templates,
		templatesByScope,
		selectedIndustries,
		selectedStyles,
		selectedSeasons,
		currentSelectedTemplatesIds,
	]);

	const stylesOptions = useMemo(() => {
		return styles
			.map((style) => ({ label: style, value: style }))
			.sort((a, b) => a.label.localeCompare(b.label));
	}, [styles]);

	const seasonsOptions = useMemo(() => {
		return seasons
			.map((season) => ({ label: season, value: season }))
			.sort((a, b) => a.label.localeCompare(b.label));
	}, [seasons]);

	const industriesOptions = useMemo(() => {
		const platformTemplates = templatesByScope.filter(
			(template) => template.scope === 'platform',
		);

		const templateIndustryIds = platformTemplates.flatMap(
			(template) => template.attributes?.industries || [],
		);

		const filteredIndustries = industries
			.filter((industry) =>
				templateIndustryIds.includes(industry.industryIds[0]),
			)
			.map((industry) => ({
				label: industry.name,
				value: industry.industryIds[0],
			}))
			.sort((a, b) => a.label.localeCompare(b.label));

		return filteredIndustries;
	}, [industries, templatesByScope]);

	const renderTemplatesGrid = (templatesList: ITemplate[]) => {
		const totalRows = Math.ceil(templatesList.length / columns);

		return (
			<Grid templateColumns={`repeat(${columns}, 1fr)`} gap={6} mt={4}>
				{templatesList
					.filter(
						(template) => !currentSelectedTemplatesIds.includes(template.id),
					)
					.map((template, index) => {
						const isHovered = hoveredTemplateId === template.id;
						const isExtendedHovered = extendedHoveredTemplateId === template.id;

						const columnIndex = index % columns;
						const rowIndex = Math.floor(index / columns);
						const transformOriginHorizontal =
							columnIndex === 0
								? 'left'
								: columnIndex === columns - 1
								? 'right'
								: 'center';
						const transformOriginVertical =
							rowIndex === 0
								? 'top'
								: rowIndex === totalRows - 1
								? 'bottom'
								: 'center';
						const transformOrigin = `${transformOriginHorizontal} ${transformOriginVertical}`;

						const isOtherTemplate =
							hoveredTemplateId && hoveredTemplateId !== template.id;

						const opacity = 1;

						const zIndex = isExtendedHovered || isHovered ? 2 : 1;

						return (
							<MotionBox
								key={template.id}
								borderWidth="3px"
								borderColor="transparent"
								borderRadius="sm"
								overflow="hidden"
								boxShadow={isExtendedHovered ? 'xl' : 'md'}
								cursor="pointer"
								bg="white"
								initial={false}
								animate={{
									scale: isHovered ? 1.3 : 1,
									opacity: opacity,
									zIndex: zIndex,
								}}
								whileTap={{ scale: 0.9 }}
								transition={{
									type: 'spring',
									stiffness: 300,
									damping: 30,
									duration: 0.3,
								}}
								transformOrigin={transformOrigin}
								onMouseEnter={() => handleHoverStart(template.id)}
								onMouseLeave={handleHoverEnd}
								onClick={() => handleTemplateSelect(template.id)}
							>
								<img
									src={template.attributes?.thumbnail}
									alt={template.name}
									style={{
										width: '100%',
										height: '100%',
										objectFit: 'cover',
									}}
								/>
							</MotionBox>
						);
					})}
			</Grid>
		);
	};

	const renderSelectedTemplates = () => {
		if (currentSelectedTemplatesIds.length === 0) {
			return null;
		}

		const handlePrevSlide = () => {
			if (swiperRef.current) {
				swiperRef.current.slidePrev();
			}
		};

		const handleNextSlide = () => {
			if (swiperRef.current) {
				swiperRef.current.slideNext();
			}
		};

		return (
			<Box
				onClick={(e) => {
					const target = e.target as HTMLElement;
					if (target.closest('.prev-slide, .next-slide')) return;

					if (!debounceRef.current) {
						debounceRef.current = true;
						setTimeout(() => {
							debounceRef.current = false;
						}, 600);

						setIsExpanded(!isExpanded);
					}
				}}
				cursor="pointer"
				borderRadius="md"
			>
				<Collapse in={!isExpanded} animateOpacity>
					<Box
						display="flex"
						mb={3}
						alignItems="center"
						justifyContent="flex-start"
						borderColor="#F7480B"
						borderRadius="md"
						p="8px"
						bg="#FFE6DD"
						transition="transform 0.3s ease-in-out"
					>
						<Flex alignItems="center">
							<Box
								width="20px"
								height="20px"
								fontSize="14px"
								borderRadius="50%"
								bg="#F7480B"
								color="white"
								display="flex"
								justifyContent="center"
								alignItems="center"
								fontWeight="bold"
								mr={2}
							>
								{currentSelectedTemplatesIds.length}
							</Box>
							<Text fontSize="14px" mr={2} color="black">
								{currentSelectedTemplatesIds.length === 1
									? 'Template selected'
									: 'Templates selected'}
							</Text>
						</Flex>
						<Flex>
							{currentSelectedTemplatesIds.slice(0, 3).map((templateId) => {
								const template =
									templates.find((t) => t.id === templateId) ||
									templatesByScope.find((t) => t.id === templateId);
								return (
									<Box
										key={templateId}
										width="30px"
										height="30px"
										borderRadius="md"
										overflow="hidden"
										mr={1}
									>
										<img
											src={template?.attributes?.thumbnail}
											alt={template?.name || 'Template'}
											style={{
												width: '100%',
												height: '100%',
												objectFit: 'cover',
											}}
										/>
									</Box>
								);
							})}
						</Flex>
						<Icon
							as={FiChevronDown}
							boxSize={6}
							color="#F7480B"
							style={{ marginLeft: 'auto' }}
						/>
					</Box>
				</Collapse>

				<Collapse in={isExpanded} animateOpacity>
					<Box position="relative" mb={3}>
						{currentSelectedTemplatesIds.length > 4 && (
							<Button
								className="prev-slide"
								position="absolute"
								top="50%"
								left="5"
								transform="translate(-50%, -50%)"
								zIndex="10"
								onClick={handlePrevSlide}
								bg={useColorModeValue('white', 'gray.700')}
								borderRadius="full"
								boxShadow="md"
								width="30px"
								height="30px"
								minWidth="auto"
								padding="0"
								_hover={{ bg: 'gray.100' }}
								style={{ display: isBeginning ? 'none' : 'block' }}
								disabled={isBeginning}
							>
								<ChevronLeftIcon
									w={6}
									h={6}
									color={isBeginning ? 'gray.400' : 'black'}
								/>
							</Button>
						)}

						<Swiper
							spaceBetween={20}
							slidesPerView="auto"
							pagination={false}
							onSwiper={(swiper) => {
								swiperRef.current = swiper;
								swiper.on('slideChange', () => {
									setIsBeginning(swiper.isBeginning);
									setIsEnd(swiper.isEnd);
								});
							}}
						>
							{currentSelectedTemplatesIds.map((templateId) => {
								const template =
									templates.find((t) => t.id === templateId) ||
									templatesByScope.find((t) => t.id === templateId);

								return (
									<SwiperSlide
										key={templateId}
										style={{
											width: '30vh',
											maxWidth: '330px',
											height: 'auto',
											maxHeight: '30vh',
										}}
									>
										<MotionBox
											className="template-container"
											position="relative"
											role="group"
											width="auto"
											height="auto"
											boxShadow="md"
											overflow="hidden"
											transition="transform 0.3s ease-in-out"
											initial={{ scale: 0.8, opacity: 0 }}
											animate={{ scale: 1, opacity: 1 }}
											exit={{ scale: 0.8, opacity: 0 }}
										>
											<Flex
												position="absolute"
												top="4px"
												right="4px"
												justifyContent="center"
												alignItems="center"
												width="20px"
												height="20px"
												zIndex="2"
												borderRadius="full"
												bg="white"
												boxShadow="sm"
												cursor="pointer"
												className="close-button"
												_hover={{
													bg: 'gray.300',
													color: 'gray.700',
												}}
												onClick={(e) => {
													e.stopPropagation();
													handleTemplateSelect(templateId);
												}}
											>
												<CloseButton size="sm" />
											</Flex>
											<img
												src={template?.attributes?.thumbnail}
												alt={template?.name || 'Template'}
												style={{
													width: '100%',
													height: '100%',
													objectFit: 'cover',
												}}
											/>
										</MotionBox>
									</SwiperSlide>
								);
							})}
						</Swiper>

						{currentSelectedTemplatesIds.length > 4 && (
							<Button
								className="next-slide"
								position="absolute"
								top="50%"
								right="5"
								transform="translate(50%, -50%)"
								zIndex="10"
								onClick={handleNextSlide}
								bg={useColorModeValue('white', 'gray.700')}
								borderRadius="full"
								boxShadow="md"
								width="30px"
								height="30px"
								minWidth="auto"
								padding="0"
								_hover={{ bg: 'gray.100' }}
								disabled={isEnd}
								style={{ display: isEnd ? 'none' : 'block' }}
							>
								<ChevronRightIcon
									w={6}
									h={6}
									color={isEnd ? 'gray.400' : 'black'}
								/>
							</Button>
						)}
					</Box>
				</Collapse>
			</Box>
		);
	};

	return (
		<CustomModal
			isOpen={isOpen}
			onClose={handleModalClose}
			modalRef={modalRef}
			header={
				<Box>
					<HStack justifyContent="space-between" w="full" mb={2}>
						<Text fontWeight="bold" fontSize="16px" color="black">
							{header}
						</Text>
					</HStack>
					<Text fontSize="14px" pb={2} mt={1}>
						Choose the templates that you would like to use
					</Text>

					{renderSelectedTemplates()}
					<Box>
						<HStack spacing={4} align="start">
							{myAccountTemplatesExist && (
								<Button
									variant="outline"
									bg={showMyTemplates ? '#FFE8E0' : 'gray.50'}
									borderRadius="5px"
									px="12px"
									_focusVisible={{ outline: 'none' }}
									borderColor={showMyTemplates ? '#ffccbc' : 'gray.200'}
									_hover={
										showMyTemplates
											? {}
											: { bg: 'gray.200', borderColor: 'gray.300' }
									}
									_active={
										showMyTemplates ? { bg: '#FFE8E0' } : { bg: 'gray.200' }
									}
									onClick={() => setShowMyTemplates(!showMyTemplates)}
									fontFamily="Noto Sans"
									fontWeight="medium"
									height="10px"
									color={showMyTemplates ? '#F7480B' : 'inherit'}
									fontSize="14px"
								>
									<Flex alignItems="center">
										<Text
											mr={showMyTemplates ? 2 : 0}
											color={showMyTemplates ? '#F7480B' : 'inherit'}
											fontWeight="medium"
										>
											My Templates
										</Text>
										{showMyTemplates && <Icon as={FiX} boxSize={4} />}
									</Flex>
								</Button>
							)}

							<FilterDropdown
								label="Industry"
								options={industriesOptions}
								selectedOptions={selectedIndustries}
								onChange={setSelectedIndustries}
							/>

							<FilterDropdown
								label="Style"
								options={stylesOptions}
								selectedOptions={selectedStyles}
								onChange={setSelectedStyles}
							/>
							<FilterDropdown
								label="Seasonal"
								options={seasonsOptions}
								selectedOptions={selectedSeasons}
								onChange={setSelectedSeasons}
							/>

							{hasSelectedFilters && (
								<Button
									onClick={handleResetAll}
									bg="gray.100"
									borderRadius="5px"
									color="black"
									_hover={{ bg: 'gray.200' }}
									fontFamily="Noto Sans"
									height="10px"
									fontWeight="medium"
									fontSize="14px"
								>
									Reset All
								</Button>
							)}
						</HStack>
					</Box>
				</Box>
			}
			footer={
				<Flex justify="flex-end" align="center" w="full">
					<Button
						isDisabled={currentSelectedTemplatesIds.length === 0}
						variant="orangeSolid"
						_hover={{ opacity: 0.9 }}
						_disabled={{ opacity: 0.7, cursor: 'not-allowed' }}
						onClick={handleGenerateDesign}
					>
						{source === 'sidebar' || source === 'gallery'
							? 'Generate ad'
							: 'Generate design'}
					</Button>
				</Flex>
			}
			size={size}
			isTemplateModal={true}
		>
			{isLoading ? (
				<FusionLoading isLoading={true} />
			) : (
				<Box>
					{showMyTemplates && (
						<>
							{filteredTemplates.myTemplates &&
							filteredTemplates.myTemplates.length > 0 ? (
								<>
									{!hasSelectedFilters && (
										<Text fontSize="14px" fontWeight="regular" mb={4}>
											My Templates
										</Text>
									)}
									{renderTemplatesGrid(filteredTemplates.myTemplates)}
								</>
							) : (
								<Box
									display="flex"
									minHeight="300px"
									flexGrow={1}
									alignItems="center"
									justifyContent="center"
									width="100%"
									height="100%"
									flexDirection="column"
								>
									<Flex alignItems="center">
										{filteredTemplates.totalMyTemplates > 0 ? (
											<Text
												textAlign="center"
												fontFamily="Noto Sans"
												fontSize="18px"
												fontWeight="bold"
											>
												No more results
											</Text>
										) : (
											<Text
												textAlign="center"
												fontFamily="Noto Sans"
												fontSize="18px"
												fontWeight="bold"
											>
												No templates found
											</Text>
										)}
										{hasSelectedFilters && (
											<Text
												as="button"
												color="blue.500"
												onClick={handleResetAll}
												textDecoration="underline"
												fontSize="14px"
												cursor="pointer"
												ml={2}
											>
												Remove filters
											</Text>
										)}
									</Flex>
								</Box>
							)}
						</>
					)}

					{!showMyTemplates && (
						<>
							{filteredTemplates.myTemplates &&
								filteredTemplates.myTemplates.length > 0 && (
									<>
										{!hasSelectedFilters && (
											<Text fontSize="14px" fontWeight="regular" mb={4}>
												My Templates
											</Text>
										)}
										{renderTemplatesGrid(filteredTemplates.myTemplates)}
									</>
								)}

							{filteredTemplates.allTemplates &&
							filteredTemplates.allTemplates.length > 0 ? (
								<>
									{!hasSelectedFilters && (
										<Text fontSize="14px" fontWeight="regular" my={4}>
											All Templates
										</Text>
									)}
									{renderTemplatesGrid(filteredTemplates.allTemplates)}
								</>
							) : (
								<Box
									display="flex"
									minHeight="300px"
									flexGrow={1}
									alignItems="center"
									justifyContent="center"
									width="100%"
									height="100%"
									flexDirection="column"
								>
									<Flex alignItems="center">
										{filteredTemplates.totalAllTemplates > 0 ? (
											<Text
												textAlign="center"
												fontFamily="Noto Sans"
												fontSize="18px"
												fontWeight="bold"
											>
												No more results
											</Text>
										) : (
											<Text
												textAlign="center"
												fontFamily="Noto Sans"
												fontSize="18px"
												fontWeight="bold"
											>
												No templates found
											</Text>
										)}
										{hasSelectedFilters && (
											<Text
												as="button"
												color="blue.500"
												onClick={handleResetAll}
												textDecoration="underline"
												fontSize="14px"
												cursor="pointer"
												ml={2}
											>
												Remove filters
											</Text>
										)}
									</Flex>
								</Box>
							)}
						</>
					)}
				</Box>
			)}
		</CustomModal>
	);
};

export default SelectTemplateModal;
