import { Outlet, useLocation } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import Sidebar from './Sidebar';
import AssistantChat from 'src/components/assistantChat';
import { AssistantChatContext } from 'src/contexts';
import TopBar from './TopBar';
import { usePopup } from 'src/contexts/PopupContext';
import useWindowSize from 'src/hooks/useWindowSize';
import UserContext from 'src/contexts/UserContext';
import DesktopForcer from 'src/components/common/DesktopForcer';
import DeviceNotSupported from 'src/components/common/DeviceNotSupported';
import { BREAKPOINTS } from 'src/components/campaigns/utils/constants';
import SelectTemplateModal from 'src/components/campaigns/design-directions/SelectTemplateModal';

const PAGES_WITH_OWN_WRAPPER = ['/home', '/tasks', '/campaigns'];

const MainLayout = () => {
	const { isChatOpen } = useContext(AssistantChatContext);
	const { pathname } = useLocation();
	const { isPopupVisible, setPopupVisible } = usePopup();
	const { width } = useWindowSize();
	const stopDekstop = width < BREAKPOINTS.desktop;
	const { user } = useContext(UserContext);
	const shouldForceDesktop = user && stopDekstop && !isPopupVisible;
	const [dekstopForced, setDekstopForced] = useState(shouldForceDesktop);
	const { setAdTemplateModalOpen, isAdTemplateModalOpen } = usePopup();

	const isTaksPath = pathname.includes('tasks');
	const isCampaignPath = pathname.includes('/projects/campaigns/');

	useEffect(() => {
		if (width < BREAKPOINTS.mobile) {
			setPopupVisible(true);
			return;
		}
		setPopupVisible(false);
	}, [width, setPopupVisible]);

	const handleClosePopup = () => {
		setPopupVisible(false);
		sessionStorage.setItem('popupClosed', 'true');
	};

	return (
		<Box h="100vh" w="100vw" m={0}>
			{shouldForceDesktop && (
				<DesktopForcer
					isAuthenticated={!!user}
					setDesktopForced={setDekstopForced}
				/>
			)}

			{isPopupVisible && width < BREAKPOINTS.mobile && (
				<DeviceNotSupported onClose={handleClosePopup} />
			)}
			{!isPopupVisible && (
				<>
					{!dekstopForced && !isCampaignPath && <Sidebar />}{' '}
					<TopBar dekstopForced={dekstopForced!} />
					<Box
						minH="calc(100vh - 72px)"
						w={
							!dekstopForced && !isCampaignPath
								? 'calc(100vw - 250px)'
								: '100vw'
						}
						ml={!dekstopForced && !isCampaignPath ? '250px' : 0}
						p="28px"
						bg="white"
						display="flex"
						flexDirection="column"
						overflowX="hidden"
						overflowY="auto"
					>
						{isChatOpen && <AssistantChat />}
						<Box w="100%" h="100%" mt="72px">
							{PAGES_WITH_OWN_WRAPPER.includes(pathname) || isTaksPath ? (
								<Outlet />
							) : (
								<Outlet />
							)}
						</Box>
					</Box>
				</>
			)}

			{isAdTemplateModalOpen && (
				<SelectTemplateModal
					isOpen={isAdTemplateModalOpen}
					onClose={() => {
						setAdTemplateModalOpen(false);
					}}
					source="sidebar"
				/>
			)}
		</Box>
	);
};

export default MainLayout;
