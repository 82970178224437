import {
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel,
	Box,
	Text,
} from '@chakra-ui/react';
import { useContext, useEffect, useState, useCallback, useRef } from 'react';
import {
	ICreative,
	IDesignDirection,
} from 'src/lib/schemas/campaign/newFlowCampaign';
import { CampaignContext } from 'src/contexts';
import { renderCreativesByPlacement } from './utils/renderCreativesByPlacement';
import { cloneDeep } from 'lodash';
import { toastError } from 'src/services/toast';
import { getCampaign } from 'src/services/campaign';
import FusionLoading from 'src/components/common/FusionLoading';
import { IChannelCreativeAttributes, IChannelGroup } from 'src/lib/schemas';

interface CreativesProps {
	creatives: ICreative[];
	setCreatives: (creatives: ICreative[]) => void;
	setLoadingCreatives: (val: boolean) => void;
	loadingCreatives: boolean;
	channels: string[];
	designDirection?: IDesignDirection;
	creativesConfig?: IChannelCreativeAttributes[];
	availableChannelGroups: IChannelGroup[];
}

const Creatives = ({
	creatives,
	setCreatives,
	setLoadingCreatives,
	loadingCreatives,
	channels,
	designDirection,
	creativesConfig,
	availableChannelGroups,
}: CreativesProps) => {
	const { campaign, id: campaignId } = useContext(CampaignContext);
	const [selectedChannel, setSelectedChannel] = useState<string>(channels[0]);
	const [selectedCreatives, setSelectedCreatives] = useState<ICreative[]>([]);
	const showLoading = Boolean(
		selectedCreatives?.every(
			(c) => c.status === 'pending' || c.status === 'generating',
		),
	);
	const areCreativesPending = creatives?.some(
		(c) => c.status === 'pending' || c.status === 'generating',
	);
	const timeoutId = useRef<any>(null);

	const handleRefetchCreatives = async () => {
		if (!campaignId) return;
		try {
			const response = await getCampaign(campaignId);
			setCreatives(response.creatives ?? []);
			const isPending = response.creatives?.some(
				(c) => c.status === 'pending' || c.status === 'generating',
			);

			if (isPending) {
				timeoutId.current = setTimeout(handleRefetchCreatives, 1000);
			} else {
				clearTimeout(timeoutId.current);
				timeoutId.current = undefined;
			}
		} catch (error: any) {
			toastError(error);
		}
	};

	useEffect(() => {
		(!selectedChannel || !channels.includes(selectedChannel)) &&
			setSelectedChannel(channels[0]);
	}, [channels, selectedChannel]);

	useEffect(() => {
		if (areCreativesPending && !timeoutId.current) {
			handleRefetchCreatives();
		}
		setLoadingCreatives(false);
	}, [areCreativesPending, timeoutId.current, campaign]);

	const filterCreativesByChannel = useCallback(
		(creatives: ICreative[]) =>
			creatives?.filter((creative) => creative.channel === selectedChannel),
		[selectedChannel],
	);

	const filterCreativesByDD = useCallback(
		(creatives: ICreative[]) =>
			creatives?.filter(
				(creative) => creative.designDirectionId === designDirection?.id,
			),
		[designDirection?.id],
	);

	useEffect(() => {
		if (!creatives) return;
		let creativesToShow: ICreative[] | undefined = cloneDeep(creatives);
		creativesToShow = filterCreativesByChannel(creativesToShow);
		creativesToShow = filterCreativesByDD(creativesToShow);
		setSelectedCreatives(creativesToShow);
	}, [
		creatives,
		filterCreativesByChannel,
		filterCreativesByDD,
		selectedChannel,
	]);

	const channelMapping = availableChannelGroups?.reduce(
		(acc, group) => {
			group.channels.forEach((channel) => {
				acc[channel.id] = channel.name;
			});
			return acc;
		},
		{} as Record<string, string>,
	);

	const renderTabPanelContent = (channel: string) => (
		<Box>
			{!designDirection ? (
				<Text textAlign="center" fontSize="md">
					Select a design above to preview the content for{' '}
					{channelMapping ? channelMapping[channel] : channel}
				</Text>
			) : (
				renderCreativesByPlacement({
					creatives: selectedCreatives,
					creativesConfig,
				})
			)}
		</Box>
	);

	return (
		<Box
			border="1px"
			borderRadius="md"
			borderColor="gray.200"
			bg="white"
			w="full"
			mx="auto"
			mt={5}
		>
			<Box borderBottom="1px solid" borderColor="gray.200" p={4}>
				<Text size="md" mb={2}>
					Preview of marketing content in each channel
				</Text>
				<Text fontSize="sm" color="gray.500">
					Select a tab to view channel-specific content and edit content of
					individual designs.
				</Text>
			</Box>
			<Tabs index={channels.indexOf(selectedChannel)}>
				{designDirection && (
					<Box bg="gray.50" p={2} pl={3}>
						<Text size="md">Option {designDirection.variant} Preview</Text>
					</Box>
				)}
				<TabList bg="gray.50">
					{channels.map((channel) => (
						<Tab
							data-testid="channel-tablist"
							key={channel}
							onClick={() => setSelectedChannel(channel)}
						>
							{channelMapping ? channelMapping[channel] : channel}
						</Tab>
					))}
				</TabList>

				<TabPanels bg="gray.100">
					{showLoading ? (
						<FusionLoading isLoading={showLoading} />
					) : (
						channels.map((channel) => (
							<TabPanel key={channel} p={4}>
								{renderTabPanelContent(channel)}
							</TabPanel>
						))
					)}
				</TabPanels>
			</Tabs>
		</Box>
	);
};

export default Creatives;
