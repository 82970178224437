import { FC, useState, useEffect, useRef } from 'react';
import {
	Box,
	HStack,
	VStack,
	Text,
	Button,
	FormErrorMessage,
	FormControl,
} from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';
import { useFormContext } from 'react-hook-form';
import { IChannel, IChannelGroup } from 'src/lib/schemas';
import FilteredChannelsSection from './FilteredChannelsSection';
import { useChannelConfig } from './UseChannelConfig';
import { ChannelType } from './ChannelType';
import PlacementsChannels from './PlacementsChannels';
import { delay } from 'src/lib/utils';

interface MarketingChannelsFormProps {
	availableChannels: IChannel[];
	availableChannelGroups: IChannelGroup[];
	requirementsLabel?: string;
	selectedPlacements: string[];
	onChannelChange: (channelId: string, channelType: ChannelType) => void;
	onPlacementChange: (placementId: string) => void;
	requirementsLabelStyle?: React.CSSProperties;
	MarketingLabelStyle?: React.CSSProperties;
}

const stylesWithErros = {
	borderColor: '#E53E3E',
	boxShadow: '0 0 0 1px #E53E3E',
};

const MarketingChannelsForm: FC<MarketingChannelsFormProps> = ({
	availableChannels,
	availableChannelGroups,
	requirementsLabel,
	selectedPlacements,
	onChannelChange,
	onPlacementChange,
	requirementsLabelStyle,
}) => {
	const {
		register,
		clearErrors,
		formState: { errors },
	} = useFormContext();
	const channelsConfig = useChannelConfig(availableChannels);
	const [isAdvanced, setIsAdvanced] = useState(false);
	const errorRef = useRef<HTMLDivElement>(null);

	const toggleAdvanced = () => {
		setIsAdvanced(!isAdvanced);
	};

	const handleChannelChange = (channelId: string, channelType: ChannelType) => {
		onChannelChange(channelId, channelType);
		clearErrors('channels');
	};

	useEffect(() => {
		const scrollToError = async () => {
			if (errors.placements && errorRef.current) {
				await delay(200);
				const elementPosition =
					errorRef.current.getBoundingClientRect().top + window.pageYOffset;
				const offsetPosition = elementPosition - 200;

				window.scrollTo({
					top: offsetPosition,
				});
			}
		};

		scrollToError();
	}, [errors.placements]);

	const renderLabelIndicator = () => {
		if (errors.placements) return <InfoIcon color="#e53e3e" ml="3px" />;
		return (
			<Text color="#e53e3e" ml="2px">
				*
			</Text>
		);
	};

	return (
		<FormControl isInvalid={Boolean(errors.placements)} ref={errorRef}>
			<VStack align="start" spacing={4} ref={register('channels').ref}>
				<HStack justifyContent="space-between" width="full">
					<VStack align="start" spacing={1}>
						<HStack>
							<Text fontWeight={500} color="black" fontSize="16px">
								Where do you want to show your ads?
							</Text>
							{renderLabelIndicator()}
						</HStack>
						<Text
							fontSize="12px"
							fontWeight={500}
							color="#959595"
							style={requirementsLabelStyle}
						>
							{requirementsLabel}
						</Text>
						{errors.placements && (
							<FormErrorMessage>
								{String(errors.placements.message)}
							</FormErrorMessage>
						)}
					</VStack>
					<Button
						variant="outline"
						color="#F7480B"
						borderColor="#F7480B"
						fontSize="14px"
						_hover={{ bg: 'none' }}
						onClick={toggleAdvanced}
					>
						{isAdvanced ? 'Switch to simple' : 'Switch to advanced'}
					</Button>
				</HStack>
				<Box
					p={4}
					bg="gray.100"
					borderWidth="1px"
					borderRadius="lg"
					w="full"
					{...(errors.placements ? stylesWithErros : {})}
				>
					<VStack align="start" spacing={6}>
						{isAdvanced ? (
							<PlacementsChannels
								availableChannels={availableChannelGroups}
								onChange={onPlacementChange}
								selectedPlacements={selectedPlacements}
							/>
						) : (
							channelsConfig.map(
								({ title, type, channels }) =>
									channels.length > 0 && (
										<FilteredChannelsSection
											key={type}
											title={title}
											type={type}
											channels={channels}
											onChange={handleChannelChange}
											selectedPlacements={selectedPlacements}
										/>
									),
							)
						)}
					</VStack>
				</Box>
			</VStack>
		</FormControl>
	);
};

export default MarketingChannelsForm;
